import React from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Header from '../Common/Header.js'
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
// import Link from '@material-ui/core/Link';
import Footer from '../Common/Footer'
import DocumentationButton from '../Docs/DocumentationButton'

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    position: 'relative',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  cardActions: {
    textAlign: "center",
    // justifyContent: 'center'
    justifyContent: 'space-between',
    padding: theme.spacing(1, 2, 1)
  },
  cardActionsRight: {
    textAlign: "center",
    justifyContent: 'flex-end',
    padding: theme.spacing(1, 2, 1)
  },
  overlay: {
    position: 'absolute',
    top: '5px',
    right: '5px',
    padding: '5px',
    color: 'white',
    fontWeight: 'bold',
    backgroundColor: 'red'
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

// const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9];

export default function Album() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <Header title="Mission Planning Toolkit" subtitle="(alpha)" />
      <main>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Container maxWidth="md">
            <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
              Mission Planning Toolkit
            </Typography>
            <Typography variant="h5" align="center" color="textSecondary" paragraph>
            A suite of automated tools for searching, filtering, and processing orbital data 
            from space agencies with customer experience in mind. 
            {/* Developed in-house by our engineers with customer experience in mind, the toolkit 
            streamlines the processes involved in mission planning, allowing the user to more quickly and easily 
            acquire desired information. */}
            </Typography>

            {/* <div className={classes.heroButtons}>
              <Grid container spacing={2} justify="center">
                <Grid item>
                  <Button variant="contained" color="primary">
                    Main call to action
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="outlined" color="primary">
                    Secondary action
                  </Button>
                </Grid>
              </Grid>
            </div> */}

          </Container>
        </div>
        <Container className={classes.cardGrid} maxWidth="md">
          {/* End hero unit */}
          <Grid container spacing={4}>
            {/* {cards.map((card) => ( */}
              {/* <Grid item key={card} xs={12} sm={6} md={4}> */}


              
              <Grid item key="georef" xs={12} sm={6} md={4}>
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.cardMedia}
                    image="https://ispace-cdn.fra1.digitaloceanspaces.com/mpsg-ispace_georef-320px.png"
                    title="Georeferencing"
                  />
                  <div className={classes.overlay}>
                    (alpha)
                  </div>
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                      Search & Georeference
                    </Typography>
                    <Typography>
                      Search for images of the Moon's surface and georeference them into your desired coordinate system.
                    </Typography>
                  </CardContent>
                  <CardActions className={classes.cardActions} >
                    <Button variant="contained" color="primary" href="/georef">
                      Start
                    </Button>
                    <DocumentationButton DocumentationType='Button' ButtonTitle="Documentation" DialogTitle="Search & Georeference documentation" DocumentationFile="/docs/georef.md" />
                  </CardActions>
                  {/* <CardActions className={classes.cardActionsRight} >
                    <DocumentationButton ButtonTitle="API"  DialogTitle="Search & Georeference API documentation" DocumentationFile="/docs/georef_api.md" />
                  </CardActions> */}
                </Card>                
              </Grid>

              <Grid item key="skypos" xs={12} sm={6} md={4}>
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.cardMedia}
                    image="https://ispace-cdn.fra1.digitaloceanspaces.com/mpsg-full_moon-800px-16-10.png"
                    title="Sky position"
                  />
                  <div className={classes.overlay}>
                    (alpha)
                  </div>
                  <CardContent className={classes.cardContent}>
                  <Typography gutterBottom variant="h5" component="h2">
                      Sky position
                    </Typography>
                    <Typography>
                    Plot the sun and earth positions as seen from the provided moon coordinates.
                    </Typography>
                  </CardContent>
                  <CardActions className={classes.cardActions} >
                    <Button variant="contained" color="primary" href="/skypos" >
                      Start
                    </Button>
                  </CardActions>
                </Card>                
              </Grid>

              <Grid item key="missionwindow" xs={12} sm={6} md={4}>
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.cardMedia}
                    image="https://ispace-cdn.fra1.digitaloceanspaces.com/mpsg-full_moon-800px-16-10.png"
                    title="Mission Window"
                  />
                  <div className={classes.overlay}>
                    (alpha)
                  </div>
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                      Mission Window calculator
                    </Typography>
                    <Typography>
                      Calculate the dates and lengths of the longest possible mission windows 
                      based on the provided lunar coordinates.
                    </Typography>
                  </CardContent>
                  <CardActions className={classes.cardActions} >
                    <Button variant="contained" color="primary" href="/missionwindow" >
                      Start
                    </Button>
                  </CardActions>
                </Card>                
              </Grid>


              <Grid item key="illumination" xs={12} sm={6} md={4}>
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.cardMedia}
                    image="https://ispace-cdn.fra1.digitaloceanspaces.com/mpsg-full_moon-800px-16-10.png"
                    title="Illumination"
                  />
                  <div className={classes.overlay}>
                    (in development)
                  </div>
                  <CardContent className={classes.cardContent}>
                  <Typography gutterBottom variant="h5" component="h2">
                      Illumination calculator
                    </Typography>
                    <Typography>
                    Plot the horizon and solar elevation angle of a given point to get illumination characteristics for a position on the lunar surface.
                    </Typography>
                  </CardContent>
                  <CardActions className={classes.cardActions} >
                    <Button variant="contained" color="primary" href="/missionwindow" disabled>
                      Start
                    </Button>
                  </CardActions>
                </Card>                
              </Grid>

            {/* ))} */}
          </Grid>
        </Container>
      </main>
      {/* Footer */}
      <Footer />
      {/* End footer */}
    </React.Fragment>
  );
}
