import React from 'react';
import { MissionWindowForm } from "./MissionWindowForm";

import Container from '@material-ui/core/Container'
import Header from '../Common/Header.js'
import Footer from '../Common/Footer.js'

class MissionWindow extends React.Component {

    render() {
        return (
        <div>
          <Header title="Mission Window" subtitle="(alpha)"/>
          <Container maxWidth="md">
            <MissionWindowForm />
          </Container>
          <Footer />
        </div>);
      }
}

// const SkyposPage = withStyles(useStyles)(Skypos);
export { MissionWindow };

