import React, {Component, PureComponent} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import {TabPanel, a11yProps} from '../../helpers/TabPanel'
import MissionWindowResultsTable from './MissionWindowResultsTable'
import MissionWindowResultsBarchart from './MissionWindowResultsBarchart'
import MissionWindowResultsCSVbutton from './MissionWindowResultsCSVbutton'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function MissionWindowResultTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} aria-label="Mission Window results tabs">
          <Tab label="Bar chart" {...a11yProps(0)} />
          <Tab label="Table" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        {/* Chart 1 */}
        <MissionWindowResultsBarchart data_rows={props.data_rows} classes={classes}/>
        
        {/* Download Horizon as CSV Button */}
        <MissionWindowResultsCSVbutton  classes={props.classes} 
                                        processing={props.processing} 
                                        apiresult={props.apiresult}
                                        data_rows={props.data_rows}
                                        />
      </TabPanel>

      <TabPanel value={value} index={1}>
        {/* Table 1 */}
        <MissionWindowResultsTable data_columns={props.data_columns} data_rows={props.data_rows} />

        {/* Download Horizon as CSV Button */}
        <MissionWindowResultsCSVbutton  classes={props.classes} 
                                processing={props.processing} 
                                apiresult={props.apiresult}
                                data_rows={props.data_rows}
                                />
      </TabPanel>
    </div>
  );
}