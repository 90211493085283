import React from "react";
import TextField from "@material-ui/core/TextField";
import {
  DateRangePicker,
  DateRangeDelimiter,
  LocalizationProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns"; // choose your lib

export default function CDateRangePicker(props) {
  // const [selectedDate, handleDateChange] = React.useState([null, null]);

  return (
    <LocalizationProvider dateAdapter={DateFnsUtils}>
      <DateRangePicker
        required
        startText="Start time"
        endText="End time"
        inputFormat="yyyy-MM-dd"
        toolbarFormat="yyyy-MM-dd"
        mask="____-__-__"
        value={props.selectedDate}
        onChange={props.onChange}
        // error={!!props.errorMessage.selectedDate}

        renderInput={(startProps, endProps) => (
          <React.Fragment>
            <TextField {...startProps} />
            <DateRangeDelimiter> to </DateRangeDelimiter>
            <TextField {...endProps} />
          </React.Fragment>
        )}
      />
    </LocalizationProvider>
  );
}
