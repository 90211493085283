import React from 'react'

import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Divider from '@material-ui/core/Divider'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox'
import Tooltip from '@material-ui/core/Tooltip'

export default function ProjectionOptions(props) {
  const [projection, setProjection] = React.useState('equirectangular')
  const [crop, setCrop] = React.useState(false)

  const onProjectionChange = (event) => {
    setProjection(event.target.value)
    props.onProjectionChange({
      projection: event.target.value,
      crop
    })
  }

  const onCropChange = (event) => {
    setCrop(event.target.checked)
    props.onProjectionChange({
      projection,
      crop: event.target.checked
    })
  }

  return (
    <Box my={1}>
      <Card>
        <CardHeader title="Projection Options"/>
        <Divider />
        <CardContent>
         <FormControl component="fieldset">
            <FormLabel component="legend">Projection</FormLabel>
            <RadioGroup value={projection} onChange={onProjectionChange}>
              <FormControlLabel label="Equirectangular" value="equirectangular" control={<Radio />} />
              <FormControlLabel label="Polar Stereographic" value="polar_stereographic" control={<Radio />} />
            </RadioGroup>
            <Tooltip placement="right" title="Coming soon!">
              <FormControlLabel
                control={<Checkbox />}
                label="Crop to search region"
                onChange={onCropChange}
                disabled
                />
            </Tooltip>
          </FormControl>
        </CardContent>
      </Card>
    </Box>
  )
}