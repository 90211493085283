import React, { Component } from 'react'
import 'typeface-roboto'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'

import Header from '../Common/Header.js'
import FilterSet from './FilterSet.js'
import SearchBox from './SearchBox.js'
import ImageList from './ImageList.js'
import SubmissionForm from './SubmissionForm.js'
import ProjectionOptions from './ProjectionOptions.js'
import Footer from '../Common/Footer.js'

class Georef extends Component {
  state = {
    processing: false,
    apiresult: false,
    images: [],
    filters: img => true,
    filteredImages: [],
    selectedImages: [],
    projectionOptions: {
      projection: 'equirectangular',
      crop: false
    }
  };

  applyFilters = (images, filters) => {
    return images.filter(filters)
  }

  handleImagesChange = (newImages) => {
    this.setState({processing: true});
    // console.log('handleImagesChange Georef')
    const filteredImages = this.applyFilters(newImages, this.state.filters);

    this.setState({
      images: newImages,
      filteredImages,
      selectedImages: []
    });

    if(newImages.length > 0) {
      this.setState({apiresult: true});
    } else {
      this.setState({apiresult: false});
    }
    this.setState({processing: false});
  }

  handleFiltersChange = (newFilters) => {
    // console.log('filteredImages Georef handleFiltersChange', filteredImages)
    const filteredImages = this.applyFilters(this.state.images, newFilters)

    this.setState({
      filters: newFilters,
      filteredImages
    });
  };

  getSelectedImages = () => {
    // console.log("getSelectedImages", this.state.selectedImages, this.state.images);
    let selected_images = [];

    this.state.selectedImages.forEach(element => {
      // console.log(element);
      selected_images.push(this.state.images[element-1]);
    });

    // console.log(this.state.selectedImages)
    // console.log(selected_images)
    
    return selected_images;
  };

  handleSelectionChange = (newSelected) => {
    // console.log("Georef handleSelectionChange");
    // console.log("Georef newSelected:", newSelected);
    this.setState({
      selectedImages: newSelected
    })
  }

  handleProjectionChange = (newProjectionOptions) => {
    this.setState({
      projectionOptions: newProjectionOptions
    })
  }

  render() {
    const tableTitle = `${this.state.images.length} found,
                        ${this.state.filteredImages.length} shown,
                        ${this.state.selectedImages.length} selected`
    return (
    <div>
      <Header title="Georeferencing" subtitle="(alpha)"/>
      <Container maxWidth="md">
        <Box my={1} >
          <Card>
            <SearchBox onSearchComplete={this.handleImagesChange} />

            <React.Fragment>
              {this.state.apiresult && <ImageList
                  title={tableTitle}
                  images={this.state.filteredImages}
                  onSelectionChange={this.handleSelectionChange}
                />
              }
              {this.state.apiresult && <FilterSet onFiltersChange={this.handleFiltersChange} />}

              {/* {this.state.selectedImages.length > 0 && <ProjectionOptions onProjectionChange={this.handleProjectionChange} />} */}
              {this.state.apiresult && <ProjectionOptions onProjectionChange={this.handleProjectionChange} />}
              {this.state.apiresult && <SubmissionForm
                  images={this.getSelectedImages()}
                  projectionOptions={this.state.projectionOptions}
                />
              }
            </React.Fragment>
          </Card>
        </Box>
      </Container>
      <Footer />
    </div>);
  }

}

export { Georef };
