import React from 'react';
import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import downloadCSVFromJson from '../../helpers/download-csv-from-json'

export default function MissionWindowResultsCSVbutton(props) {
    return (
      // <CardActions className={props.classes.cardActions} >
      <div className={props.classes.buttonWrapper}>

        <Button
          size="small" 
          startIcon={<CloudDownloadIcon />}
          onClick={() => {
            if (!props.processing || props.apiresult) {
              downloadCSVFromJson(`missino-window.csv`, props.data_rows);
            }
          }}
          variant="contained" 
          color="primary" 
          disabled={props.processing || !Boolean(props.data_rows.length>0)}
        >
          Data as CSV
        </Button>
      </div>
    // </CardActions>
    );
}