import React, {Component} from 'react';
import { withStyles } from '@material-ui/styles';
import { green } from '@material-ui/core/colors';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
// import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider'
// import TextField from '@material-ui/core/TextField';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Typography from '@material-ui/core/Typography'
import Menu from '@material-ui/core/Menu';
// import MenuItem from '@material-ui/core/MenuItem';
import DocumentationButton from '../Docs/DocumentationButton'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import Select from '@material-ui/core/Select';
import CDateRangePicker from '../Common/CDateRangePicker'
import getApiUrl from '../../helpers/apiHelper'
import { secondsToString, secondsToDays, formatDateString } from '../../helpers/dataManipulation';
import MissionWindowResultsTabs from './MissionWindowResultsTabs'

// Slider
// import Slider from '@material-ui/core/Slider';


const styles = theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      // width: '25ch',
    },
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonWrapper: {
    margin: theme.spacing(2,0,0,0),
    position: 'relative',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  cardActions: {
    textAlign: "center",
    // justifyContent: 'center'
    justifyContent: 'space-between',
    padding: theme.spacing(1, 2, 1)
  },
  cardActionsRight: {
    textAlign: "center",
    justifyContent: 'flex-end',
    padding: theme.spacing(1, 2, 1)
  },
  datePicker: {
    paddingTop: '10px'
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  // buttonSuccess: {
  //   backgroundColor: green[500],
  //   '&:hover': {
  //     backgroundColor: green[700],
  //   },
  // },
});

const columns = [
  { field: 'id', headerName: 'ID', width: 90 },
  { field: 'start_date', headerName: 'Start date', width: 160 },
  { field: 'end_date', headerName: 'End date', width: 160 },
  { field: 'length_string', headerName: 'Length', width: 150,
            sortComparator: (v1, v2, param1, param2) =>
              param1.api.getCellValue(param1.id, 'length_in_seconds') -
              param2.api.getCellValue(param2.id, 'length_in_seconds'), 
            },
  { field: 'length_in_seconds', headerName: 'Length in seconds', width: 195 },
];


class MissionWindowFormComponent extends Component {

  state = { 
    anchorEl: null,
    processing: false,
    processingSuccess: false,
    apiresult: null,
    mission_window_data: [],

    // Form fields
    latitude: null,
    longitude: null,
    selectedDate: [null, null],
    time_step: "6h",
    observer_height: null,
    min_solar_ill: 1,

    // Helper fields for calling API
    start_time: null,
    end_time: null,

    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    errorMessage: {},

    selectedTab: 0
  }
  
  checkError(response) {
    if (response.status >= 200 && response.status <= 299) {
      try {
        // JSON.parse(response);
        return response.json();
      } catch(e) {
          throw Error(e);
      }
      
    } else {
      throw Error(response.statusText);
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    // console.log('handleChange:', name, ' ', value, '', event.target);
    this.setState({ [name]: value });
  }

  handleTabChange = (event, newValue) => {
    // console.log('handleTabChange:', newValue);
    this.setState({'selectedTab': newValue})
  };
  
  handleDateChange = (event) => {
    this.setState({selectedDate: event})
    
    // start_date
    if(event[0]) {
      this.setState({start_time: event[0].toISOString()})
    }

    // end_date
    if(event[1]) {
      event[1].setHours(23, 59, 59, 999)
      this.setState({end_time: event[1].toISOString()})
    }
    // console.log(event)
  }


  handleSubmit = (event) => {
    // console.log("Submit called.")
    let t0= performance.now(); //start time
    let isError = false;
    this.setState({error: false});
    this.setState({errorMessage: {}});

    if (!this.state.processing) {
      this.setState({processing: true});
      this.setState({processingSuccess: false});
      this.setState({mission_window_data: []});
    }

    if (this.state.selectedDate[0] == null) {
      isError = true;
      console.log("Missing date range!");
      this.setState({
        errorMessage: { selectedDate: "Enter correct date range" }
      });
    }

    if (isError) {
      if (this.state.processing) {
        this.setState({processing: false});
        this.setState({processingSuccess: false});
        this.setState({mission_window_data: []});
      }

      return false;
    }
    
    let headers = new Headers();

    // console.log(JSON.parse(localStorage.getItem('user')).authdata)
    // console.log(Buffer.from(username + ":" + password).toString('base64'))

    const apiURL = getApiUrl('mission_window');
    apiURL.searchParams.append("coordinate.latitude", this.state.latitude);  // -79.0
    apiURL.searchParams.append("coordinate.longitude", this.state.longitude);  // 11.0
    apiURL.searchParams.append("start_time", this.state.start_time); // "2020-08-31T00:00:00.000Z"
    apiURL.searchParams.append("end_time", this.state.end_time); // "2020-09-30T00:00:00.000Z"
    apiURL.searchParams.append("time_step", this.state.time_step);  // "12h"
    apiURL.searchParams.append("minimumum_solar_illumination", this.state.min_solar_ill);  // "12h"
    
    headers.set('Content-Type', 'application/json');

    // fetch Mission Windows data
    fetch(apiURL.href, {
                          method:'GET',
                          headers: headers,
                        }
        )
        .then(this.checkError)
        .then((jsonResponse) => {
          console.log(jsonResponse);

          this.setState({processing: false});
          this.setState({processingSuccess: true});

          var row_id = 1;
          var mission_window_data_array = [];
        //   Mission Windows data manipulation
        jsonResponse.windows.forEach(element => {
            console.log(element);
            if ('length' in element) { // don't show window if it has no length
                let d = new Date(element.start);
                // Calculate end date of the mission window
                d.setSeconds(d.getSeconds() + parseInt(element.length));
                mission_window_data_array.push({
                                                    id: row_id,
                                                    start_date: formatDateString(element.start),
                                                    end_date: formatDateString(d.toString()),
                                                    length_string: secondsToString(parseInt(element.length), false), 
                                                    length_in_seconds: parseInt(element.length),
                                                    length_in_days: secondsToDays(parseInt(element.length)),
                                                });
            }
            row_id++;
        });

            this.setState({mission_window_data: mission_window_data_array});
            this.setState({ apiresult: true });

            let t1= performance.now(); //end time
            console.log('Execution time:'+ (t1-t0)/1000 +' seconds');
        }).catch((error) => {
            console.log(error);
            this.setState({processing: false});
            this.setState({processingSuccess: false});
            alert(error);
            let t1= performance.now(); //end time
            console.log('Execution time:'+ (t1-t0)/1000 +' seconds');
        });

  }

  handleMenuClick = (event) => {
    this.setState({anchorEl: event.currentTarget});
  }

  handleMenuClose = () => {
    this.setState({anchorEl: null});
  }


  render() {
    const {classes} = this.props;
    return (
      
    <Box my={1}>
        <Card>
          <CardHeader title="Mission Window"
              action={
                <React.Fragment>
                  <IconButton aria-controls="more-menu" aria-haspopup="true"  aria-label="settings" onClick={this.handleMenuClick}>
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="more-menu"
                    anchorEl={this.state.anchorEl}
                    keepMounted
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.handleMenuClose}
                  >
                  <DocumentationButton callOnClick={this.handleMenuClose} DocumentationType='MenuItem' ButtonTitle="Documentation" DialogTitle="Sky position" DocumentationFile="/docs/missionwindow.md" />
                  {/* <MenuItem onClick={this.handleMenuClose}>Documentation</MenuItem> */}
                  {/* <MenuItem onClick={this.handleMenuClose}>My account</MenuItem> */}
                </Menu>
              </React.Fragment>
              }
          />

          <Divider />
          
          <React.Fragment>
            {this.state.apiresult && <MissionWindowResultsTabs classes={classes} 
                                        processing={this.state.processing} 
                                        apiresult={this.state.apiresult}
                                        data_columns={columns}
                                        data_rows={this.state.mission_window_data}
                                        />
            }
          </React.Fragment>

          {/* FORM */}
          <ValidatorForm
                  className={classes.root}
                  // ref="form"
                  ref={r => (this.form = r)}
                  onSubmit={this.handleSubmit}
                  onError={error => console.log(this.state.error)}
          >
            <CardContent className={classes.cardContent}>
                <Grid container
                      justify="flex-start"
                      alignItems="center"
                      spacing={2}
                      >
                  <Grid item xs={8} sm={4}>
                  <TextValidator
                      mr={1}
                      fullWidth
                      // error={Number.isNaN(this.state.lat)}
                      // size="small"
                      variant="outlined"
                      id="latitude" 
                      name="latitude"
                      label="Latitude"
                      value={this.state.latitude}
                      onChange={this.handleChange}
                      validators={[
                        'required',
                        'isFloat',
                        'minFloat:-90.0',
                        'maxFloat:90.0',
                      ]}
                      errorMessages={[
                        'Required field',
                        'Must be a number',
                        'Min valid number is -90.0',
                        'Max valid number is 90.0',
                      ]}
                      error={this.state.error}
                      helperText={this.state.error}
                    />
                </Grid>
                <Grid item xs={8} sm={4}>
                  <TextValidator
                      fullWidth
                      // error={Number.isNaN(this.state.lon)}
                      // size="small"
                      variant="outlined"
                      id="longitude"
                      name="longitude"
                      label="Longitude"
                      value={this.state.longitude}
                      onChange={this.handleChange}
                      validators={[
                        'required',
                        'isFloat',
                        'minFloat:-180.0',
                        'maxFloat:180.0'
                      ]}
                      errorMessages={[
                        'Required field',
                        'Must be a number',
                        'Min valid number is -180.0',
                        'Max valid number is 180.0',
                      ]}
                      />
                </Grid>
                <Grid item xs={8} sm={4}>
                </Grid>
                <Grid item xs={8} sm={4} className={classes.datePicker}>
                  <CDateRangePicker onChange={this.handleDateChange} selectedDate={this.state.selectedDate} errorMessage={this.state.errorMessage} />
                </Grid>
                <Grid item xs={4} sm={2}>
                  <FormControl fullWidth variant="outlined" >
                    <InputLabel id="time_step">Time step</InputLabel>
                    <Select
                      labelId="time_step"
                      id="time_step"
                      name="time_step"
                      value={this.state.time_step}
                      onChange={this.handleChange}
                    >
                      <MenuItem value="1h">1 hour</MenuItem>
                      <MenuItem value="2h">2 hours</MenuItem>
                      <MenuItem value="6h">6 hour</MenuItem>
                      <MenuItem value="12h">12 hour</MenuItem>
                      <MenuItem value="24h">24 hour</MenuItem>
                    </Select>
                    <FormHelperText>Round to x hours</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={4} sm={2}>
                  <FormControl fullWidth variant="outlined" >
                    <TextValidator
                        fullWidth
                        // error={Number.isNaN(this.state.observer_height)}
                        // size="small"
                        variant="outlined"
                        id="observer_height"
                        name="observer_height"
                        label="Observer height"
                        value={this.state.observer_height}
                        onChange={this.handleChange}
                        validators={[
                          'required',
                          'isFloat',
                          'minFloat:0.0',
                          'maxFloat:2000.0'
                        ]}
                        errorMessages={[
                          'Required field',
                          'Must be a number',
                          'Min valid number is 0',
                          'Max valid number is 2000',
                        ]}
                        />
                      <FormHelperText>Height in meters</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4} sm={2}>
                  <FormControl fullWidth variant="outlined" >
                      <InputLabel id="min_solar_ill">Min. solar illumination</InputLabel>
                      <Select
                          labelId="min_solar_ill"
                          id="min_solar_ill"
                          name="min_solar_ill"
                          value={this.state.min_solar_ill}
                          validators={['required']}
                          onChange={this.handleChange}
                      >
                          <MenuItem value="0.1">10%</MenuItem>
                          <MenuItem value="0.2">20%</MenuItem>
                          <MenuItem value="0.3">30%</MenuItem>
                          <MenuItem value="0.4">40%</MenuItem>
                          <MenuItem value="0.5">50%</MenuItem>
                          <MenuItem value="0.6">60%</MenuItem>
                          <MenuItem value="0.7">70%</MenuItem>
                          <MenuItem value="0.8">80%</MenuItem>
                          <MenuItem value="0.9">90%</MenuItem>
                          <MenuItem value="1">100%</MenuItem>
                      </Select>
                    {/* <TextValidator
                        fullWidth
                        // error={Number.isNaN(this.state.observer_height)}
                        // size="small"
                        variant="outlined"
                        id="min_solar_ill"
                        name="min_solar_ill"
                        label="Min. solar illumination"
                        value={this.state.min_solar_ill}
                        onChange={this.handleChange}
                        validators={[
                          'required',
                          'isFloat',
                          'minFloat:0.0',
                          'maxFloat:1.0'
                        ]}
                        errorMessages={[
                          'Required field',
                          'Must be a number',
                          'Min valid number is 0',
                          'Max valid number is 1',
                        ]}
                        /> */}
                      <FormHelperText>% of sun visible</FormHelperText>
                    </FormControl>
                  </Grid>
                  
                </Grid>
            </CardContent>

          <CardActions className={classes.cardActionsRight} >
            <div className={classes.wrapper}>
               {/* onClick={this.submit} */} 
              <Button type="submit" variant="contained" color="primary" disabled={this.state.processing} >
                Submit
              </Button>
              {this.state.processing && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
          </CardActions>
          <Grid item>
            <Typography color="error">{this.state.error}</Typography>
          </Grid>
        </ValidatorForm>
      </Card>
    </Box>
    )
  }
}

const MissionWindowForm = withStyles(styles)(MissionWindowFormComponent);

export {MissionWindowForm};