import React from 'react';
import Album from './Album'

class LandingPage extends React.Component {

    toGeoref = (e) => {
        e.preventDefault();

        const to = { pathname: "/georef" };
        this.props.history.push(to);
    }

    render() {
        return (
            <Album />
        );
    }
}

export { LandingPage }; 