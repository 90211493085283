import React from 'react';
import {  XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Bar, BarChart, LabelList } from 'recharts';

import {  formatDateString } from '../../helpers/dataManipulation';

const MissionWindowTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`Start date: : ${formatDateString(payload[0].payload.start_date)}`}</p>
        <p className="label">{`End date: : ${formatDateString(payload[0].payload.end_date)}`}</p>
      </div>
    );
  }

  return null;
};

export default function MissionWindowResultsBarchart(props) {
    return (
      <ResponsiveContainer minWidth={400} minHeight={300} >
        <BarChart width={730} height={250} data={props.data_rows}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="start_date" />
            <YAxis/> {/* label={{ value: 'Mission duration in days, hours', angle: -90, position: 'insideStart', fill: 'grey' }} */}
            <Tooltip content={<MissionWindowTooltip />} />
            <Legend />
            <Bar dataKey="length_in_days" name="Mission duration in days and hours" fill="#8884d8" position="top" minPointSize={5}>
                <LabelList dataKey="length_string" /> {/* label={{ value: 'Mission duration' }} */}
            </Bar>
        </BarChart>
    </ResponsiveContainer>
    );
}