import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography'
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ReactMarkdown from 'react-markdown'
import MenuItem from '@material-ui/core/MenuItem';

// Based on Dialog - Material UI
// max-width dialog - https://material-ui.com/components/dialogs/
export default class DocumentationButton extends React.Component {
  state = {
      open: false,
      scroll: 'paper',
      documentContent: '',
    }

  componentDidMount() {
      fetch(this.props.DocumentationFile)
        .then(r => r.text())
        .then(text  => {
          // console.log(text)
          this.setState({documentContent: text});
        })
  }

    handleClickOpen = (scrollType) => () => {
      this.setState({'open': true})
      this.setState({'scrollType': scrollType})
    };

    handleClickMenuOpen = (scrollType) => () => {
      this.props.callOnClick()
      this.setState({'open': true})
      this.setState({'scrollType': scrollType})
    };

    handleClose = () => {
      this.setState({'open': false})
    };

    render(props) {
      const isButtonType = this.props.DocumentationType.toLowerCase() === 'Button'.toLowerCase();
      let button;

      if (isButtonType) {
        button = <Button variant="contained" onClick={this.handleClickOpen('paper')} >
                    <Typography variant="caption">
                      {this.props.ButtonTitle}
                    </Typography>
                </Button>;
      } else {
        button = <MenuItem onClick={this.handleClickMenuOpen('paper')}>Documentation</MenuItem>;
      }

      return (
       
        <div>
          {button}
          
          <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            scroll={this.state.scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            maxWidth='md'
          >
            <DialogTitle id="scroll-dialog-title">{this.props.DialogTitle}</DialogTitle>
            <DialogContent dividers={this.state.scroll === 'paper'}>
              <Typography>
                <ReactMarkdown>{this.state.documentContent}</ReactMarkdown>
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={this.handleClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      );
    }
}