export function secondsToString(seconds, printZeros=true)
{
    var numyears = Math.floor(seconds / 31536000);
    var numdays = Math.floor((seconds % 31536000) / 86400); 
    var numhours = Math.floor(((seconds % 31536000) % 86400) / 3600);
    var numminutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60);
    var numseconds = (((seconds % 31536000) % 86400) % 3600) % 60;

    var resultStringWithoutZeros = "";
    if (numyears !== 0 && printZeros === true) {
        resultStringWithoutZeros += numyears + " years ";
    }
    if (numdays !== 0 ) {
        resultStringWithoutZeros += numdays + " days ";
    }
    if (numhours !== 0 ) {
        resultStringWithoutZeros += numhours + " hours ";
    }
    if (numminutes !== 0 ) {
        resultStringWithoutZeros += numminutes + " minutes ";
    }
    if (numseconds !== 0 ) {
        resultStringWithoutZeros += numseconds + " seconds ";
    }
    if (printZeros === true) {
        return numyears + " years " +  numdays + " days " + numhours + " hours " + numminutes + " minutes " + numseconds + " seconds";
    }

    return resultStringWithoutZeros;
}

export function secondsToDays(seconds)
{
    var numdays = Math.floor((seconds % 31536000) / 86400);

    return numdays;
}

export function formatDateString(dateString) {
    var temp = new Date(dateString);
    
    var dateStr = padStr(temp.getFullYear()) +'-'+
                  padStr(1 + temp.getMonth()) +'-'+
                  padStr(temp.getDate()) +' '+
                  padStr(temp.getHours()) +':'+
                  padStr(temp.getMinutes()) +':'+
                  padStr(temp.getSeconds());
    
    return dateStr;
}

function padStr(i) {
    return (i < 10) ? "0" + i : "" + i;
}