import React, { useState } from 'react'

import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip';
import Slider from '@material-ui/core/Slider'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField'
import {DatePicker, LocalizationProvider} from "@material-ui/pickers"
import {toDate, addDays} from 'date-fns'
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns"

export default function FilterSet(props) {
  const [incidenceRange, setIncidenceRange] = useState([0, 90])
  const [solarAzimuthRange, setSolarAzimuthRange] = useState([0, 360])
  const [emissionRange, setEmissionRange] = useState([0,5])
  const [phaseAngleRange, setPhaseAngleRange] = useState([0,90])
  const [startDate, setStartDate] = useState(toDate(Date.UTC(2009,5,18)))
  const [endDate, setEndDate] = useState(toDate(Date.now()))

  const between = (a,x,b) => a <= x && x <= b
  const inRange = (range, x) => between(range[0],x,range[1])
  const getFilter = () => {
    const filter = img => {
      return inRange(incidenceRange, img.incidence_angle) &&
             inRange(solarAzimuthRange, img.subsolar_azimuth) &&
             inRange(emissionRange, img.emission_angle) &&
             inRange(phaseAngleRange, img.phase_angle) &&
             between(startDate,img.start_time,endDate)
    }
    return filter
  }

  const notifyFilterChange = () => {
    props.onFiltersChange(getFilter())
  }

  const setToGMT = (date) => {
    // Date returned from the date picker are in the local time zone,
    // this moves it to Midnight GMT to match the images' timezone
    date.setHours(0,0,0,0)
    let ms = date.getTime() - date.getTimezoneOffset()*60*1000
    return toDate(ms)
  }
  const updateStartDate = (newValue) => {
    setStartDate(setToGMT(newValue))
    notifyFilterChange()
  }
  const updateEndDate = (newValue) => {
    newValue = setToGMT(newValue)
    newValue = addDays(newValue,1)
    setEndDate(newValue)
    notifyFilterChange()
  }

  return (
    <Box my={1}>
      <Card>
        <CardHeader title="Results filters" />
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item>
              <Tooltip title="The incidence angle (INC) is the angle between the sun and the surface normal." placement="left-start">
                <Typography>Incidence Angle</Typography>
              </Tooltip>
            </Grid>
            <Grid item xs>
              <Box px={2}>
                <Slider
                  defaultValue={[0,90]}
                  min={0}
                  max={90}
                  label="Incidence Angle"
                  label-position="left"
                  onChange={(e,v) => setIncidenceRange(v)}
                  onChangeCommitted={notifyFilterChange}
                  valueLabelDisplay="on"
                  />
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item>
              <Tooltip title="The Ground azimuth to the SubSolar point is obtained by taking true North (90 deg Latitude) and finding the clockwise angle to the subsolar latitude and longitude point. The Subsolar Ground Azimuth is complementary to the incidence angle and gives more information about which direction the sun is coming from." placement="left-start">
                <Typography>Subsolar Azimuth</Typography>
              </Tooltip>
            </Grid>
            <Grid item xs>
              <Box px={2}>
                <Slider
                  defaultValue={[0,360]}
                  min={0}
                  max={360}
                  label="Subolar Azimuth"
                  label-position="left"
                  onChange={(e,v) => setSolarAzimuthRange(v)}
                  onChangeCommitted={notifyFilterChange}
                  valueLabelDisplay="on"
                  />
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item>
              <Tooltip title="The emission angle (EMA) is the angle between the spacecraft and a vector drawn perpendicular to the planet's surface (surface normal)."  placement="left-start">
                <Typography>Emission Angle</Typography>
              </Tooltip>
            </Grid>
            <Grid item xs>
              <Box px={2}>
                <Slider
                  defaultValue={[0,5]}
                  min={0}
                  max={5}
                  step={0.05}
                  label="Emission Angle"
                  label-position="left"
                  onChange={(e,v) => setEmissionRange(v)}
                  onChangeCommitted={notifyFilterChange}
                  valueLabelDisplay="on"
                  />
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item>
              <Tooltip title="The phase angle is the angle between the sun and the spacecraft at a point on the surface. Phase Angle is in degrees." placement="left-start">
                <Typography>Phase Angle</Typography>
              </Tooltip>
            </Grid>
            <Grid item xs>
              <Box px={2}>
                <Slider
                  defaultValue={[0,90]}
                  min={0}
                  max={90}
                  label="Phase Angle"
                  label-position="left"
                  onChange={(e,v) => setPhaseAngleRange(v)}
                  onChangeCommitted={notifyFilterChange}
                  valueLabelDisplay="on"
                  />
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item>
              <Tooltip title="The date when the picture was taken." placement="left-start">
                <Typography>Observation date</Typography>
              </Tooltip>
            </Grid>
            <Grid item>
              <Box px={2}>
                <LocalizationProvider dateAdapter={DateFnsUtils}>
                  <DatePicker
                    label="Start Date"
                    value={startDate}
                    minDate={Date.UTC(2009,5,18)}
                    maxDate={new Date()}
                    onChange={updateStartDate}
                    mask="____-__-__"
                    inputFormat="yyyy-MM-dd"
                    renderInput={(props) => {
                      return <TextField variant="outlined" {...props} />
                    }}
                  />
                </LocalizationProvider>
              </Box>
            </Grid>
            <Grid item>
              <Box px={2}>
                <LocalizationProvider dateAdapter={DateFnsUtils}>
                  <DatePicker
                    label="End Date"
                    value={endDate}
                    minDate={Date.UTC(2009,5,18)}
                    maxDate={new Date()}
                    onChange={updateEndDate}
                    mask="____-__-__"
                    inputFormat="yyyy-MM-dd"
                    renderInput={(props) => {
                      return <TextField variant="outlined" {...props} />
                    }}
                  />
                </LocalizationProvider>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  )
}