import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import ArrowBackIosOutlined from '@material-ui/icons/ArrowBackIosOutlined'
// import HomeOutlined from '@material-ui/icons/HomeOutlined'
// import Grid from '@material-ui/core/Grid'

var on_landingpage = false;
if (window.location.pathname === '' || window.location.pathname === '/') {
  on_landingpage = true
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
      },
    logo: {
      height: '1.5rem',
      marginRight: '1rem'
    },
    title: {
      marginTop: '0.33rem',
      lineHeight: 0.75,
  
    },
    alpha: {
      flexGrow: 1,
      marginTop: '-1rem',
      marginLeft: '0.2rem'
    },
    logout: {
      height: '1.5rem',
      marginRight: '1rem'
    },
    icon: {
        marginRight: theme.spacing(2),
    }
  })
)

export default function Header(props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
        <AppBar position="static">
            <Toolbar>
              {!on_landingpage &&
                <Link href="/" color="inherit" >
                  <ArrowBackIosOutlined className={classes.icon} />
                </Link>
              }
                <Link href="/" color="inherit" >
                  <img src="/img/logo_white.png" className={classes.logo} alt="ispace logo" />
                </Link>
                <Typography variant="h6" color="inherit" noWrap>{props.title}</Typography>
                <Typography variant="caption" className={classes.alpha}>{props.subtitle}</Typography>
                <Button variant="contained" color="primary" href="/login">
                    Logout
                </Button>
            </Toolbar>
        </AppBar>
    </div>
  )
}