import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import { DataGrid } from '@material-ui/data-grid';
// import MaterialTable from '@material-table/core'
import LroImageThumbnail from './LroImageThumbnail.js'


export default function ConfirmationModal(props) {
  let projectionString = 'an equirectangular'
  if (props.projection === 'polar_stereographic') {
    projectionString = 'a polar stereographic'
  }

  let imageCountString = '1 image'
  if (props.images.length > 1) {
    imageCountString = props.images.length + ' images'
  }
  
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>Order Confirmation</DialogTitle>
      <DialogContent>
        <DialogContentText>
        <Typography>{imageCountString} will be processed in {projectionString} projection and the results will be emailed to {props.email}.</Typography>
        <DataGrid 
                    columns={[
                      { field: 'id', headerName: 'ID', width: 90, hide: true },
                      { field: 'product_id', headerName: 'Image ID', width: 135 },
                      {
                        field: '',
                        headerName: 'Thumbnail',
                        width: 160 ,
                        renderCell: (rowData) => (<LroImageThumbnail image={rowData} />),
                      },
                      // { field: 'incidence_angle', headerName: 'Solar incidence', width: 140 },
                      // { field: 'subsolar_azimuth', headerName: 'Subsolar azimuth', width: 140 },
                      // { field: 'emission_angle', headerName: 'Emission angle', width: 140 },
                      // { field: 'phase_angle', headerName: 'Phase angle', width: 155 },
                      // { field: 'start_time', headerName: 'Observation Date', width: 190,
                      //       valueFormatter: (params) => {
                      //         return format(new Date(params.row.start_time),'yyyy-MM-dd HH:mm:ss');
                      //       },
                      // },
                      
                    ]}
                    autoHeight={true}
                    rows={props.images}
                    pageSize={10}
                    disableSelectionOnClick
                    checkboxSelection={false}
                    // isRowSelectable={(params) => false}
                    // onSelectionModelChange={(newSelectionModel) => {
                    //   props.onSelectionChange(newSelectionModel);
                    // }}
            
          />
        {/* <MaterialTable
          title="Selected Images"
          columns={[
            {
              title: 'ID', 
              field: 'product_id', 
              width: '10rem',
              cellStyle: {
                "font-size": "small",
                "font-family": "sans-serif"
              }
            },
            {
              title: 'Thumbnail',
              field: 'incidence_angle',
              width: '500px',
              cellStyle: {overflow: 'hidden'},
              render: rowData => <LroImageThumbnail image={rowData} />
            }
          ]}
          data={props.images}
          options={{
            search: false,
            pageSize: Math.min(props.images.length,10),
            pageSizeOptions: [10, 20, 50, 100],
            padding: 'dense',
            draggable: false,
            showtitle: false,
            showTextRowsSelected: false,
          }}
        /> */}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onClose(false)} variant="contained">Cancel</Button>
        <Button onClick={() => props.onClose(true)}  variant="contained" color="primary">Process!</Button>
      </DialogActions>
    </Dialog>
    )
}