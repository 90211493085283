import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
// import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import getApiUrl from '../../helpers/apiHelper'
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/styles';
import { green } from '@material-ui/core/colors';

const styles = theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      // width: '25ch',
    },
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  }
});

class BBoxSearchFormComponent extends Component{
  state = { 
    north: 0,
    south: 0,
    east: 0,
    west: 0,
    processing: false,
    error: null
  }

  handleSubmit = () => {
    if (!this.state.processing) {
      // console.log('processing...')
      this.setState({processing: true});
    }

    const apiURL = getApiUrl('image_search')
    // let {north, east, south, west} = this.state
    const region = `POLYGON((${this.state.east} ${this.state.north},${this.state.east} ${this.state.south},${this.state.west} ${this.state.south},${this.state.west} ${this.state.north},${this.state.east} ${this.state.north}))`
    apiURL.searchParams.append('region', region)
    apiURL.searchParams.append('return_all', 1)

    // console.log(apiURL)
    // const url = `/api/v0/image_search/bbox?north=${north}&east=${east}&south=${south}&west=${west}`
    // const url = `api/search/bbox?north=${north}&east=${east}&south=${south}&west=${west}`
    fetch(apiURL.href)
      .then(response => {
        if(response.status >= 400) {
          this.setState({processing: false });
          // console.log("Done processing 400")
          throw new Error("Server responded with error: "+response.status+": "+response.statusText+"!");
        }
        return response.json();
      })
      .then((data) => {
        this.props.onSearchComplete(data.images)
        this.setState({processing: false });
        // console.log("Done processing data")
      })
  }

  handleChange = (dir) => {
    return (event) => {
      // console.log('handleChange')
      // console.log(this.state)
      // console.log(event.target.name)
      const newState = this.state
      // newState[dir] = parseFloat(event.target.value)
      newState[dir] = event.target.value
      
      this.setState(newState)
    }
  }

  render() {
    const {classes} = this.props;
    return (
      <Grid container>
        <ValidatorForm
              className={classes.root}
              // ref="form"
              ref={r => (this.form = r)}
              onSubmit={this.handleSubmit}
              onError={error => console.log(error)}
        >
          <Grid item xs={5}>
              <Grid container justify="space-evenly">
                <Grid item xs={4}>
                  <TextValidator
                    size="small"
                    variant="outlined"
                    label="North"
                    name="north"
                    value={this.state.north}
                    onChange={this.handleChange('north')}
                    validators={[
                      'required',
                      'isFloat',
                      'minFloat:-90.0',
                      'maxFloat:90.0',
                    ]}
                    errorMessages={[
                      'Required field',
                      'Must be a number',
                      'Min valid number is -90.0',
                      'Max valid number is 90.0',
                    ]}
                    />
                </Grid>
              </Grid>
              <Grid container justify="space-between">
                <Grid item xs={4}>
                  <TextValidator
                    size="small"
                    variant="outlined"
                    label="West"
                    name="west"
                    value={this.state.west}
                    onChange={this.handleChange('west')}
                    validators={[
                      'required',
                      'isFloat',
                      'minNumber:-180',
                      'maxNumber:180',
                    ]}
                    errorMessages={[
                      'Required field',
                      'Must be a number',
                      'Min valid number is -180',
                      'Max valid number is 180'
                    ]}
                    />
                </Grid>
                <Grid item xs={4}>
                  <TextValidator
                    size="small"
                    variant="outlined"
                    label="East"
                    name="east"
                    value={this.state.east}
                    onChange={this.handleChange('east')}
                    validators={[
                      'required',
                      'isFloat',
                      'minNumber:-180',
                      'maxNumber:180',
                    ]}
                    errorMessages={[
                      'Required field',
                      'Must be a number',
                      'Min valid number is -180',
                      'Max valid number is 180',
                    ]}
                    />
                </Grid>
              </Grid>
              <Grid container justify="space-evenly">
                <Grid item xs={4}>
                  <TextValidator
                    // error={Number.isNaN(this.state.south)}
                    size="small"
                    variant="outlined"
                    label="South"
                    name="south"
                    value={this.state.south}
                    onChange={this.handleChange('south')}
                    validators={[
                      'required',
                      'isFloat',
                      'minNumber:-90',
                      'maxNumber:90',
                    ]}
                    errorMessages={[
                      'Required field',
                      'Must be a number',
                      'Min valid number is -90',
                      'Max valid number is 90'
                    ]}
                    />
                </Grid>
              </Grid>
          </Grid>
          <Grid container>
            <Box pt={2}>
              <div className={classes.wrapper}>
                {/* onClick={this.handleSubmit} */}
                <Button type="submit" variant="contained" color="primary" disabled={this.state.processing} >
                  Search
                </Button>
                {this.state.processing && <CircularProgress size={24} className={classes.buttonProgress} />}
              </div>
            </Box>
          </Grid>
        </ValidatorForm>
      </Grid>
    )
  }
}

const BBoxSearchForm = withStyles(styles)(BBoxSearchFormComponent);
export default BBoxSearchForm;