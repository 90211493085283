import React from 'react'

import './LroImageThumbnail.css'

export default function LroImageThumbnail(props) {
  // console.log(props);
  // const img = props.image
  const img = props.image.row
  const volumeId = 'LROLRC_1' + img.volume_id.substr(8)
  const date = img.file_specification_name.split('/')[4]
  const productId = img.product_id.substr(0, img.product_id.length-1) + 'C'
  const thumbnailUrl = window.location.protocol+"//wms.lroc.asu.edu/ptif/zoomify/ser/estore/lroc/web/LRO-L-LROC-3-CDR-V1.0/" +
                       `${volumeId}/EXTRAS/BROWSE/${date}/${productId}_pyr.tif/TileGroup0/0-0-0.jpg`
  const previewUrl = `${window.location.protocol}//wms.lroc.asu.edu/lroc/view_lroc/LRO-L-LROC-2-EDR-V1.0/${img.product_id}`

  return (
    <div className="lro-thumbnail">
      <a href={previewUrl} target="_blank" rel="noopener noreferrer">
        <img src={thumbnailUrl} alt={`Thumbnail for ${img.product_id}`}/>
      </a>
    </div>
  )
}