import React from 'react';
import ReactDOM from 'react-dom';
import './index.css'
import App from './App';
import * as serviceWorker from './helpers/serviceWorker';

// fake login backend
import configureFakeBackend from './helpers/fake-backend';
var config = {apiUrl: `${window.location.origin.toString()}/auth/`} // Production

if (config.apiUrl.includes('localhost')) {
  // setup fake login backend
  configureFakeBackend();
}


ReactDOM.render(
  <React.StrictMode>
    <App />    
 </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
