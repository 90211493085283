import React from 'react';
// Rechart
import { ResponsiveContainer } from 'recharts';
import { DataGrid } from '@material-ui/data-grid';


export default function MissionWindowResultsTable(props) {
    return (
        <ResponsiveContainer minWidth={400} minHeight={300}  spacing={0}>
            <div style={{ height: 400, width: '100%' }}>
              <DataGrid 
                rows={props.data_rows}
                columns={props.data_columns}
                pageSize={5}
                checkboxSelection={false}
                // disableSelectionOnClick
              />
            </div>
          </ResponsiveContainer>
    );
}