import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import CardHeader from '@material-ui/core/CardHeader'
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider'
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@material-ui/data-grid';
import Button from '@material-ui/core/Button'
import LroImageThumbnail from './LroImageThumbnail.js'
import format from 'date-fns/format'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonWrapper: {
    margin: theme.spacing(2,0,0,0),
    position: 'relative',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  cardActions: {
    textAlign: "center",
    // justifyContent: 'center'
    justifyContent: 'space-between',
    padding: theme.spacing(1, 2, 1)
  },
  cardActionsRight: {
    textAlign: "center",
    justifyContent: 'flex-end',
    padding: theme.spacing(1, 2, 1)
  },
}));

function ExportToolbar() {
  const classes = useStyles();

  return (
    <GridToolbarContainer className={classes.cardActionsRight} >
          <Button variant="contained"><GridToolbarExport /></Button>
    </GridToolbarContainer>
  );
}

export default function ImageList(props) {
  const classes = useStyles();

  return (
    <div>
          <CardHeader title={props.title} />
          <Divider />
          {/* <CardContent> */}
          <DataGrid 
            components={{
              Toolbar: ExportToolbar,
            }}
            columns={[
              { field: 'id', headerName: 'ID', width: 90, hide: true },
              // { field: 'product_id', headerName: 'Image ID', width: 135 },
              {
                field: '',
                headerName: 'Thumbnail',
                width: 160 ,
                valueFormatter: (rowData) => `${window.location.protocol}//wms.lroc.asu.edu/lroc/view_lroc/LRO-L-LROC-2-EDR-V1.0/${rowData.row.product_id}`,
                renderCell: (rowData) => (<LroImageThumbnail image={rowData} />),
              },
              { field: 'incidence_angle', headerName: 'Solar incidence', width: 140 },
              { field: 'subsolar_azimuth', headerName: 'Subsolar azimuth', width: 140 },
              { field: 'emission_angle', headerName: 'Emission angle', width: 140 },
              { field: 'phase_angle', headerName: 'Phase angle', width: 155 },
              { field: 'start_time', headerName: 'Observation Date', width: 190,
                    valueFormatter: (params) => {
                      return format(new Date(params.row.start_time),'yyyy-MM-dd HH:mm:ss');
                    },
              },
              
            ]}
            autoHeight={true}
            rows={props.images}
            pageSize={10}
            // disableSelectionOnClick
            checkboxSelection={true}
            // isRowSelectable={(params) => params.row.quantity > 50000}
            onSelectionModelChange={(newSelectionModel) => {
              props.onSelectionChange(newSelectionModel);
            }}
            
          />
        
        {/* </CardContent> */}
      
        {/* <MaterialTable
          title={props.title}
          columns={[
            {
              title: 'ID', 
              field: 'product_id', 
              width: default_width,
              cellStyle: cellStyle,
              headerStyle: cellStyle
            },
            {
              title: 'Solar incidence',
              field: 'incidence_angle',
              width: default_width,
              cellStyle: cellStyle,
              headerStyle: cellStyle,
              defaultSort: 'asc',
              render: img => img.incidence_angle.toFixed(2)
            },
            {
              title: 'Subsolar azimuth',
              field: 'subsolar_azimuth',
              width: default_width,
              cellStyle: cellStyle,
              headerStyle: cellStyle,
              render: img => img.subsolar_azimuth.toFixed(2)
            },
            {
              title: 'Emission angle',
              field: 'emission_angle',
              width: default_width,
              cellStyle: cellStyle,
              headerStyle: cellStyle,
              render: img => img.emission_angle.toFixed(2)
            },
            {
              title: 'Phase angle',
              field: 'phase_angle',
              width: default_width,
              cellStyle: cellStyle,
              headerStyle: cellStyle,
              render: img => img.phase_angle.toFixed(2)
            },
            {
              title: 'Observation Date',
              field: 'start_time',
              width: default_width,
              cellStyle: cellStyle,
              headerStyle: cellStyle,
              render: img => format(new Date(img.start_time),'yyyy-MM-dd')
            },
            {
              title: 'Thumbnail',
              field: 'incidence_angle',
              width: '150px',
              cellStyle: {overflow: 'hidden'},
              render: rowData => <LroImageThumbnail image={rowData} />
            }
          ]}
          data={props.images}
          options={{
            selection: true,
            search: false,
            pageSize: 10,
            pageSizeOptions: [10, 20, 50, 100],
            padding: 'dense',
            draggable: false,
            showtitle: true,
            showTextRowsSelected: false,
          }}
          onSelectionChange={props.onSelectionChange}
        /> */}
      {/* </div> */}
    </div>
  )
}