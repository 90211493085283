import React, {Component} from 'react'

import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Divider from '@material-ui/core/Divider'
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import PropTypes from 'prop-types';
import {TabPanel, a11yProps} from '../../helpers/TabPanel'
import BBoxSearchForm from './BBoxSearchForm.js'
import RadiusSearchForm from './RadiusSearchForm.js'
// import DocumentationButton from '../Docs/DocumentationButton'
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
// import MenuItem from '@material-ui/core/MenuItem';
import DocumentationButton from '../Docs/DocumentationButton'
import parseJSON from 'date-fns/parseJSON'


const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
});

class SearchBox extends Component {
  state = { 
    tabIndex: 0,
    anchorEl: null,
    emptySearchResult: false
  }

  handleTabChange = (event, tabIndex) => {
    this.setState({tabIndex})
  }

  handleMenuClick = (event) => {
    // console.log('handleMenuClick')
    this.setState({anchorEl: event.currentTarget});
  }

  handleMenuClose = () => {
    this.setState({anchorEl: null})
  }

  handleSearchComplete = (imgs) => {
    console.log('handleSearchComplete SearchBox')
    //Check if the result is empty
    if(typeof imgs !== 'undefined') {
      this.setState({emptySearchResult: false});
      let i = 1;
      imgs.forEach(img => {
        img.id = i++;
        img.start_time = parseJSON(img.start_time)
        img.stop_time = parseJSON(img.stop_time)
      })

      this.props.onSearchComplete(imgs)
    } else {
      this.setState({emptySearchResult: true})
      console.log('No results found!')
      this.props.onSearchComplete([])
    }
    // console.log(imgs)
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Card className={classes.root}>
          <CardHeader title="Search"
              action={
                <>
                  <IconButton aria-controls="more-menu" aria-haspopup="true"  aria-label="settings" onClick={this.handleMenuClick}>
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="more-menu"
                    anchorEl={this.state.anchorEl}
                    keepMounted
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.handleMenuClose}
                  >
                  <DocumentationButton callOnClick={this.handleMenuClose} DocumentationType='MenuItem' ButtonTitle="Documentation" DialogTitle="Search & Georeference documentation" DocumentationFile="/docs/georef.md" />
                  {/* <MenuItem onClick={this.handleMenuClose}>Documentation</MenuItem> */}
                  {/* <MenuItem onClick={this.handleMenuClose}>My account</MenuItem> */}
                </Menu>
              </>
              }
              // action={
              //   <DocumentationButton aria-label="Documentation" ButtonTitle="Documentation" DialogTitle="Search & Georeference documentation" DocumentationFile="/docs/georef.md" />
              // }
          />
          <Divider />
          <AppBar position="static">
            <Tabs value={this.state.tabIndex} onChange={this.handleTabChange}  aria-label="Search form tabs">
              <Tab label="By Radius" {...a11yProps(0)} />
              <Tab label="By Bounding Box" {...a11yProps(0)} />
            </Tabs>
          </AppBar>
          <TabPanel value={this.state.tabIndex} index={0}>
            <RadiusSearchForm onSearchComplete={this.handleSearchComplete} />
          </TabPanel>
          
          <TabPanel value={this.state.tabIndex} index={1}>
            <BBoxSearchForm onSearchComplete={this.handleSearchComplete} />
          </TabPanel>
        </Card>

        <Dialog open={this.state.emptySearchResult} onClose={() => this.setState({ emptySearchResult: false})}>
          <DialogTitle>No images found</DialogTitle>
          <DialogContent>
            <DialogContentText>No images meet the selected criteria.</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState( { emptySearchResult: false })}>OK</Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

SearchBox.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SearchBox);