import React from 'react';
import { SkyposForm } from "./SkyposForm";

import Container from '@material-ui/core/Container'
import Header from '../Common/Header.js'
import Footer from '../Common/Footer.js'


class SkyposPage extends React.Component {

    toSkypos = (e) => {
        e.preventDefault();

        const to = { pathname: "/skypos" };
        this.props.history.push(to);
    }

    render() {
        return (<React.Fragment>
          <Header title="Sky position" subtitle="(alpha)"/>
          <Container maxWidth="md">
          {/* <form className={classes.root} noValidate autoComplete="off"> */}
          <SkyposForm />
          </Container>
          <Footer />
        </React.Fragment>);
      }
}

// const SkyposPage = withStyles(useStyles)(Skypos);
export { SkyposPage };

