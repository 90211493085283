import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Divider from '@material-ui/core/Divider'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import getApiUrl from '../../helpers/apiHelper'
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import ConfirmationModal from './ConfirmationModal.js'
import { withStyles } from '@material-ui/styles';
import { green } from '@material-ui/core/colors';

const styles = theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      // width: '25ch',
    },
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonWrapper: {
    margin: theme.spacing(2,0,0,0),
    position: 'relative',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  cardActions: {
    textAlign: "center",
    // justifyContent: 'center'
    justifyContent: 'space-between',
    padding: theme.spacing(1, 2, 1)
  },
  cardActionsRight: {
    textAlign: "center",
    justifyContent: 'flex-end',
    padding: theme.spacing(1, 2, 1)
  },
  datePicker: {
    paddingTop: '10px'
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});


class SubmissionFormComponent extends Component {
  state = {
    error: null,
    email: '',
    confirmationDialogOpen: false,
    receiptDialogOpen: false
  }

  onConfirmation = (confirmed) => {
    this.setState({confirmationDialogOpen: false})
    if (!confirmed) {
      return
    }

    const apiURL = getApiUrl('process')
    apiURL.searchParams.append('email', `${this.state.email}`)

    // Add all images_ids to the url
    this.props.images.forEach(img => apiURL.searchParams.append('images', img.product_id))

    this.props.images.forEach(img => console.log(img.product_id))
    
    apiURL.searchParams.append('projection', this.props.projectionOptions.projection)
    // console.log(apiURL)
    
    // const response = fetch(apiURL)
    fetch(apiURL).then((response) => {
      // console.log(response)
      // console.log(JSON.stringify(response))
      
      if (!response.ok) {
        // console.log("Response not OK!")
        // console.log(response.status)
        if (response.status === 400) {
          const body = response.json()
          this.setState({error: body.err})
        } else {
          this.setState({ error: 'An error occurred while registering your processing request!'})
        }
        this.setState( { receiptDialogOpen: true })
      } else {
        // console.log("Response OK!")
        this.setState({error: null})
        this.setState( { receiptDialogOpen: true })
      }
    })
  }

  handleSubmit = () => {
    if (this.props.images.length === 0) {
      this.setState({error: 'No images have been selected.'})
      return
    }
    // console.log("Num. selected images: "+this.props.images.length)
    this.setState({confirmationDialogOpen: true})
  }

  render() {
    const {classes} = this.props;

    return (
      <React.Fragment>
          <CardHeader title="Submit image processing job" />
          <Divider />
          <CardContent>
            <ValidatorForm
                    // ref="form"
                    ref={r => (this.form = r)}
                    onSubmit={this.handleSubmit}
                    onError={error => console.log(this.state.error)}
              >
              <Grid container
                justify="flex-start"
                alignItems="center"
                spacing={1}
                >
                <Grid item xs={12}>
                  {this.props.images.length == 0 && <Typography color="error">Select at least one image from the list above.</Typography> 
                  }
                </Grid>

                <Grid item xs={10}>
                  <TextValidator
                    style={{width: '100%'}}
                    placeholder="your-email@email-provider.com"
                    label="Email"
                    variant="outlined"
                    size="small"
                    onChange={event => this.setState({ email: event.target.value })}
                    name="email"
                    value={this.state.email}
                    validators={['required', 'isEmail']}
                    errorMessages={['This field is required', 'Email is not valid']}
                    error={this.state.error}
                    helperText={this.state.error}
                    />
                </Grid>

                <Grid item  xs={2}>
                  <CardActions className={classes.cardActionsRight} >
                    {/* <div className={classes.wrapper}> */}
                      <Button type="submit" variant="contained" color="primary" disabled={this.props.images.length == 0} >
                        Submit
                      </Button>
                    {/* </div> */}
                  </CardActions>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Typography color="error">{this.state.error}</Typography>
              </Grid>

            </ValidatorForm>
          </CardContent>


        <ConfirmationModal
          open={this.state.confirmationDialogOpen}
          images={this.props.images}
          onClose={this.onConfirmation}
          projection={this.props.projectionOptions.projection}
          email={this.state.email}
          />
        
        {/* Submission confirmation dialog */}
        <Dialog open={this.state.receiptDialogOpen} onClose={() => this.setState({ receiptDialogOpen: false})}>
          <DialogTitle>{this.state.error == null ? 'Success' : 'Error' }</DialogTitle>
          <DialogContent>
            <DialogContentText>{this.state.error == null ? 'Your processing request is registered. You will receive an email when processing is complete.' : this.state.error }</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState( { receiptDialogOpen: false })}>OK</Button>
          </DialogActions>
        </Dialog>

      </React.Fragment>
    )
  }
}


const SubmissionForm = withStyles(styles)(SubmissionFormComponent);
export default SubmissionForm;