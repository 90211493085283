import React, {Component} from 'react';
import { withStyles } from '@material-ui/styles';
import { green } from '@material-ui/core/colors';
import CircularProgress from '@material-ui/core/CircularProgress';
// import ndjsonStream from 'can-ndjson-stream';
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
// import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Divider from '@material-ui/core/Divider'
// import TextField from '@material-ui/core/TextField';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Typography from '@material-ui/core/Typography'
import Menu from '@material-ui/core/Menu';
// import MenuItem from '@material-ui/core/MenuItem';
import DocumentationButton from '../Docs/DocumentationButton'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
// Rechart
import { Brush, ReferenceLine, Label, LineChart, ComposedChart, BarChart, Bar, Line, Area, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import CDateRangePicker from '../Common/CDateRangePicker'
import GroundStationsList from './GroundStationsList'
import getApiUrl from '../../helpers/apiHelper'
import downloadCSVFromJson from '../../helpers/download-csv-from-json'

// Slider
// import Slider from '@material-ui/core/Slider';

const styles = theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonWrapper: {
    margin: theme.spacing(0,0,0,8),
    position: 'relative',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  cardActions: {
    textAlign: "center",
    // justifyContent: 'center'
    justifyContent: 'space-between',
    padding: theme.spacing(1, 2, 1)
  },
  cardActionsRight: {
    textAlign: "center",
    justifyContent: 'flex-end',
    padding: theme.spacing(1, 2, 1)
  },
  datePicker: {
    paddingTop: '10px'
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  // gsTitle: {
  //   textTransform: 'capitalize'
  // }
  // buttonSuccess: {
  //   backgroundColor: green[500],
  //   '&:hover': {
  //     backgroundColor: green[700],
  //   },
  // },
});

class SkyposFormComponent extends Component {

  state = { 
    anchorEl: null,
    processing: false,
    processingSuccess: false,
    apiresult: null,
    apiHorizonResult: null,
    sun_earth_data: null,
    combined_data: null,
    sun_data: null,
    sun_direction: "",
    earth_data: null,
    horizon_data: [],
    latitude: null,
    longitude: null,
    observer_height: null,
    start_time: null,
    end_time: null,
    time_step: "24h",
    time_day_interval: 0,
    date_range: null,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    showGroundStationsList: false,
    selectedDate: [null, null],
    ground_station_provider: 'ESTRACK',
    gs_lists: {
                'ESTRACK': ['KIRUNA1', 'KIRUNA2', 'KOUROU', 'MASPALOMAS', 'PERTH', 'REDU', 'STA_MARIA', 'V_FRANCA', 'NNORCIA', 'NNORCIA2', 'CEBREROS', 'MALINDI', 'SANTIAGO', 'SVALBARD', 'MALARGUE'],
                'DSN': ['DSS_12', 'DSS_13', 'DSS_14', 'DSS_15', 'DSS_16', 'DSS_17', 'DSS_23', 'DSS_24', 'DSS_25', 'DSS_26', 'DSS_27', 'DSS_28', 'DSS_33', 'DSS_34', 'DSS_42', 'DSS_43', 'DSS_45', 'DSS_46', 'DSS_49', 'DSS_53', 'DSS_54', 'DSS_55', 'DSS_61', 'DSS_63', 'DSS_64', 'DSS_65', 'DSS_66']
              },
    gs_list: ['KIRUNA1', 'KIRUNA2', 'KOUROU', 'MASPALOMAS', 'PERTH', 'REDU', 'STA_MARIA', 'V_FRANCA', 'NNORCIA', 'NNORCIA2', 'CEBREROS', 'MALINDI', 'SANTIAGO', 'SVALBARD', 'MALARGUE'],
    selected_gs: [],
    visible_gs: [],
    error: null
  }

  parse_datetime(data_list) {
    var weekday = new Array(7);

    weekday[0] = "Sun";
    weekday[1] = "Mon";
    weekday[2] = "Tue";
    weekday[3] = "Wed";
    weekday[4] = "Thu";
    weekday[5] = "Fri";
    weekday[6] = "Sat";

    var monthName = new Intl.DateTimeFormat("en-US", { month: "long" }).format;
    // var shortMonthName = new Intl.DateTimeFormat("en-US", { month: "short" }).format;

    data_list.forEach((obj) => {
      for (const k in obj) {
        if (k === "time") {
            // convert time string to unixtime: "2021-05-01T22:00:00Z"-> 1619906400
            obj["unixtime"] = (new Date(obj[k]).getTime()/1000);
            obj["time-month"] = monthName(new Date(obj[k]));
            // obj["time-day-of-week"] = weekday[new Date(obj[k]).getDay()];
            obj["time-day"] = new Date(obj[k]).getDate();
            obj["time-hour"] = new Date(obj[k]).getHours();
          }
      }
    })
    return data_list
  }

  rename_key(data_list, planet) {
    data_list.forEach((obj) => {
      for (const k in obj) {
          if (k === planet+"_azimuth") {
              obj["azimuth"] = obj[k];
              delete obj[k]
          }
          if (k === planet+"_elevation") {
            obj["elevation"] = obj[k];
            delete obj[k]
          }
          // convert time string to unixtime: "2021-05-01T22:00:00Z"-> 1619906400
          if (k === "time") {
            obj["unixtime"] = (new Date(obj[k]).getTime()/1000);
          }
        
      }
    })

    return data_list
  }
  
  
  checkError(response) {
    if (response.status >= 200 && response.status <= 299) {
      try {
        // JSON.parse(response);
        return response.json();
      } catch(e) {
          throw Error(e);
      }
      
    } else {
      throw Error(response.statusText);
    }
  }

  // With => function you don't need to bind submit in the constructore!
  // Use of Lambda functions implicitly bind this!
  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });

    // console.log(name, value);
  }
  handleChangeSwitch = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: event.target.checked });
  };

  handleDateChange = (event) => {
    this.setState({selectedDate: event})
    
    // start_date
    if(event[0]) {
      // 2020-08-31T00:00:00.000Z
      // console.log(event[0].toISOString())
      this.setState({start_time: event[0].toISOString()})
    }

    // end_date
    if(event[1]) {
      // 2020-08-31T00:00:00.000Z
      // console.log(event[1].toISOString())
      event[1].setHours(23, 59, 59, 999)
      this.setState({end_time: event[1].toISOString()})
    }
    // console.log(event)
  }

  updateSelectedGS(gsArr) {
    // console.log('updateSelectedGS');
    // console.log(gsArr);
    this.setState({selected_gs: gsArr});
  }

  getVisibleGSData(gs_name) {
    // console.log('getVisibleGSData('+gs_name+')');
    const result = this.state.visible_gs.filter(gs => gs.name===gs_name);

    return result;
  }

  // With => function you don't need to bind submit in the constructore!
  // Use of Lambda functions implicitly bind this!
  handleSubmit = (event) => {

    console.log('handleSubmit');
    if (!this.state.processing) {
      this.setState({processing: true});
      this.setState({processingSuccess: false});
      this.setState({horizon_data: null});
    }

    
    switch(this.state.time_step) {
      case '24h':
        this.setState({ time_day_interval: 0 });
        break;
      case '12h':
        this.setState({ time_day_interval: 1 });
        break;
      case '6h':
        this.setState({ time_day_interval: 3 });
        break;
      case '2h':
        this.setState({ time_day_interval: 11 });
        break;
      case '1h':
        this.setState({ time_day_interval: 23 });
        break;
      default:
        this.setState({ time_day_interval: 0 });
    }

    let headers = new Headers();

    // console.log(JSON.parse(localStorage.getItem('user')).authdata)
    // console.log(Buffer.from(username + ":" + password).toString('base64'))

    // const apiUrl = new URL(window.location.protocol+"//"+window.location.host+"/api/v0/ephemerides");
    const apiURL = getApiUrl('ephemerides')
    apiURL.searchParams.append("coordinate.latitude", this.state.latitude);  // -79.0
    apiURL.searchParams.append("coordinate.longitude", this.state.longitude);  // 11.0
    apiURL.searchParams.append("start_time", this.state.start_time); // "2020-08-31T00:00:00.000Z"
    apiURL.searchParams.append("end_time", this.state.end_time); // "2020-09-30T00:00:00.000Z"
    apiURL.searchParams.append("time_step", this.state.time_step);  // "12h"
    this.state.selected_gs.forEach(gs => apiURL.searchParams.append('ground_stations', gs))
    // this.state.selected_gs.forEach(gs => console.log('ground_stations', gs))

    const apiURLHorizon = getApiUrl('horizon')
    // const apiURLHorizon = getApiUrl('horizon_stream')
    apiURLHorizon.searchParams.append("coordinate.latitude", this.state.latitude);  // -79.0
    apiURLHorizon.searchParams.append("coordinate.longitude", this.state.longitude);  // 11.0
    apiURLHorizon.searchParams.append("observer_height", this.state.observer_height);  // "2"
    
    // console.log(this.state.start_time);
    // console.log(this.state.end_time);
    // console.log(Math.abs(Date.parse(this.state.end_time) - Date.parse(this.state.start_time)));
    // console.log(Math.abs(Date.parse(this.state.end_time) - Date.parse(this.state.start_time)) / 36e5);
    // console.log(Math.ceil(hours/parseInt(this.state.time_step.slice(0, -1))));

    // var hours = Math.abs(Date.parse(this.state.end_time) - Date.parse(this.state.start_time)) / 36e5;  //milliseconds: 36e5 is the scientific notation for 60*60*1000
    var numSamples = 720;
    // var numSamples = Math.ceil(hours/parseInt(this.state.time_step.slice(0, -1)));

    apiURLHorizon.searchParams.append("samples", numSamples);
    // console.log(numSamples)

    // headers.set('Authorization', 'Basic ' + Buffer.from(username + ":" + password).toString('base64'));
    // headers.set('Authorization', 'Basic ' + JSON.parse(localStorage.getItem('user')).authdata);
    // headers.set('AuthAccess-Control-Allow-Originorization', '*');
    headers.set('Content-Type', 'application/json');

    // fetch sun and earth positions
    fetch(apiURL.href, {
                          method:'GET',
                          headers: headers,
                        }
        )
        .then(this.checkError)
        .then((jsonResponse) => {
          
          let sun_earth_data = JSON.parse(JSON.stringify(jsonResponse.measurements)); // sorted by time
          let combined_data = this.parse_datetime(sun_earth_data).sort((a,b) =>  a.azimuth-b.azimuth ); // sorted by azimuth
          let sun_data = this.rename_key(JSON.parse(JSON.stringify(jsonResponse.measurements)), 'sun').sort((a,b) =>  a.azimuth-b.azimuth );
          let earth_data = this.rename_key(JSON.parse(JSON.stringify(jsonResponse.measurements)), 'earth').sort((a,b) =>  a.azimuth-b.azimuth );
          // let combined_data = this.parse_datetime(sun_earth_data);
          // let sun_data = this.rename_key(JSON.parse(JSON.stringify(jsonResponse.measurements)), 'sun');
          // let earth_data = this.rename_key(JSON.parse(JSON.stringify(jsonResponse.measurements)), 'earth');

          // console.log(sun_earth_data[0]["sun_azimuth"]);
          // console.log(sun_earth_data[1]["sun_azimuth"]);
          // console.log(sun_earth_data);

          // extract visible ground stations data
          // let selected_gs = ['KIRUNA1', 'KIRUNA2', 'KOUROU', 'MASPALOMAS', 'REDU', 'STA_MARIA'];
          var visible_gs = [];

          sun_earth_data.forEach(element => {
            // console.log(element);
            this.state.selected_gs.forEach(gs_name => {
              var gs = {name: gs_name, time: element.time, visible: 0};
              if('visible_ground_stations' in element) {
                if (element.visible_ground_stations.some(e => e.ground_station === gs_name)) {
                  /* element contains the gs_name we're looking for */
                  gs.visible = 1;
                }
                
              }
              console.log(gs);
              visible_gs.push(gs);
            });

            //Overall coverage
            var gs_coverage={name: 'Overall coverage', time: element.time, visible: 0}
            if('visible_ground_stations' in element) {
              gs_coverage.visible=1;
            }
            visible_gs.push(gs_coverage);
          });

          if (sun_earth_data[1]["sun_azimuth"] > sun_earth_data[0]["sun_azimuth"]) {
            this.setState({sun_direction: "Sun ↣"});
          } else {
            this.setState({sun_direction: "↢ Sun"});
          }

          // before: {time: "2021-05-01T22:00:00Z", sun_azimuth: 195.63194, sun_elevation: 5.9069138}
          // after: {time: 1619906400, azimuth: 195.63194, elevation: 5.9069138}
          
          // console.log(sun_earth_data);
          // console.log(combined_data);
          // console.log(sun_data);
          // console.log(earth_data);
          console.log(visible_gs);
          
          this.setState({ sun_earth_data: sun_earth_data });
          this.setState({ combined_data: combined_data });
          this.setState({ sun_data: sun_data });
          this.setState({ earth_data: earth_data });
          this.setState({ visible_gs: visible_gs });
          
          
          this.setState({ apiresult: true });
        }).catch((error) => {
            console.log(error);
            if (this.state.processing) {
              this.setState({processing: false});
              this.setState({processingSuccess: false});
              this.setState({horizon_data: null});
            }
            alert(error);
            return false;
        });

        // fetch horizon data
        if (90.0 >= Math.abs(this.state.latitude) && Math.abs(this.state.latitude) > 79.999) {
          console.log("Show horizon data.")
          fetch(apiURLHorizon.href, {
                            method:'GET',
                            headers: headers,
                          }
          )
          // ###################################################
          // ### Horizon data batch
          // ###################################################
          .then(response => {
            if(response.status >= 400) {
              throw new Error("Server responded with error: "+response.status+": "+response.statusText+"!");
            }
            return response.json();
          })
          .then((data) => {
            var sorted_horizon_data = [];

            // JSON.parse(JSON.stringify(data.measurements))
            data.measurements.forEach((obj) => {
              let horizon_data_point = {"azimuth":0, "elevation":0, "elevation_worst_case":0, "time":""};
              horizon_data_point.elevation = obj.elevation;
              horizon_data_point.elevation_worst_case = obj.elevation_worst_case;

              if (typeof obj.azimuth !== 'undefined') {
                horizon_data_point.azimuth = obj.azimuth
              }

              // console.log(horizon_data_point);
              sorted_horizon_data.push(horizon_data_point);
            })
            
            // sorted_horizon_data.sort((a, b) => parseFloat(a.azimuth) - parseFloat(b.azimuth));
            // console.log(sorted_horizon_data);

            this.setState({ horizon_data: sorted_horizon_data });
            this.setState({ processing: false });
            this.setState({ processingSuccess: true });


            console.log(!Boolean(this.state.horizon_data.length>0))
          // ###################################################
          // ### Horizon data Stream
          // ###################################################
          // .then((response) => {
          //     return ndjsonStream(response.body); //ndjsonStream parses the response.body
          // })
          // .then((jsonStream) => {
            // const reader = jsonStream.getReader();
            // let read;
            // reader.read().then(read = (result) => {
            //   if (result.done) {
            //     this.setState({ processing: false});
            //     this.setState({ processingSuccess: true});
            //     return;
            //   };

            //   let horizon_data = this.state.horizon_data;
            //   var sorted_horizon_data = [];
            //   var data_point_inserted = false;

            //   // console.log(result.value);
            //   let horizon_data_point = {"azimuth":0, "elevation":0, "elevation_worst_case":0};
            //   horizon_data_point.elevation = result.value.result.elevation;
            //   horizon_data_point.elevation_worst_case = result.value.result.elevation_worst_case;

            //   if (typeof result.value.result.azimuth !== 'undefined') {
            //     horizon_data_point.azimuth = result.value.result.azimuth
            //   }
            //   // console.log(horizon_data_point);
              
            //   // 1st iteration
            //   if (horizon_data === null) {
            //     horizon_data = [horizon_data_point, {"azimuth":360, "elevation":horizon_data_point.elevation, "elevation_worst_case":horizon_data_point.elevation_worst_case}];
            //     sorted_horizon_data = horizon_data;
            //   } else {
            //     // later iterations
            //     for (var key in horizon_data) {
            //       // console.log(key, horizon_data[key].azimuth, horizon_data_point.azimuth);
    
            //       if (!data_point_inserted) {
            //         if (horizon_data[key].azimuth > horizon_data_point.azimuth) {
            //           sorted_horizon_data.push(horizon_data_point);
            //           data_point_inserted = true;
            //         }
            //       }
            //       sorted_horizon_data.push(horizon_data[key]);
            //     }

            //     if (!data_point_inserted) {
            //       sorted_horizon_data.push(horizon_data_point);
            //       data_point_inserted = true;
            //     }
            //   }
            //   this.setState({ horizon_data: sorted_horizon_data });
              
            //   reader.read().then(read); // Recurse through the stream
            // });

            // Test test test
            }).catch((error) => {
              console.log(error);
              if (this.state.processing) {
                this.setState({processing: false});
                this.setState({processingSuccess: false});
                this.setState({horizon_data: null});
              }
              alert(error);
              return false;
          });
        } else {
          console.log("Don't show horizon data.")
          this.setState({ horizon_data: [] });
          this.setState({ processing: false });
          this.setState({ processingSuccess: true });
        }
        // ###########################################################
  }

  handleMenuClick = (event) => {
    this.setState({anchorEl: event.currentTarget});
  }

  handleMenuClose = () => {
    this.setState({anchorEl: null});
  }

  render() {
    const {classes} = this.props;
    return (
      
    <Box my={1}>
        <Card>
          <CardHeader title="Sky position"
              action={
                <React.Fragment>
                  <IconButton aria-controls="more-menu" aria-haspopup="true"  aria-label="settings" onClick={this.handleMenuClick}>
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="more-menu"
                    anchorEl={this.state.anchorEl}
                    keepMounted
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.handleMenuClose}
                  >
                  <DocumentationButton callOnClick={this.handleMenuClose} DocumentationType='MenuItem' ButtonTitle="Documentation" DialogTitle="Sky position" DocumentationFile="/docs/skypos.md" />
                  {/* <MenuItem onClick={this.handleMenuClose}>Documentation</MenuItem> */}
                  {/* <MenuItem onClick={this.handleMenuClose}>My account</MenuItem> */}
                </Menu>
              </React.Fragment>
              }
          />
          <Divider />
          <React.Fragment>
            {this.state.apiresult && 
              <ResponsiveContainer minWidth={400} minHeight={300} spacing={0}>
                <ComposedChart
                  // syncId='elevationY'
                  margin={{
                    top: 16,
                    right: 16,
                    bottom: 0,
                    left: 24,
                  }}
                >
                  <CartesianGrid />
                  <XAxis xAxisId={0} dataKey={'azimuth'} type="number" name='azimuth' unit='°' domain={[0, 360]} >
                    <Label value="Azimuth" offset={0} position="insideBottom" 
                           style={{ textAnchor: 'middle', fontSize: '100%', fill: '#FFFFFF' }}
                    />
                  </XAxis>
                  {/* <YAxis label={{ value: 'Elevation', angle: -90, position: 'insideLeft' }} /> */}
                  <YAxis>
                    <Label value="Elevation" offset={0} position="insideBottomLeft" angle="-90"
                           style={{ textAnchor: 'middle', fontSize: '100%', fill: '#FFFFFF' }}
                    />
                  </YAxis>

                  <Area name='Moon horizon' dataKey={'elevation'} unit='°'  data={this.state.horizon_data} shape="square" fill='#999999'stroke="#8884d8"/>
                  <Area name='Moon horizon worst case' dataKey={'elevation_worst_case'} unit='°' data={this.state.horizon_data} shape="square" fill='#444444'stroke="#8884d8"/>
                  
                  <Scatter name='Sun'   dataKey={'elevation'} unit='°' legendType='star' data={this.state.sun_data} shape="star" fill='#999900'/>
                  <Scatter name='Earth' dataKey={'elevation'} unit='°' legendType='circle' data={this.state.earth_data} shape="circle" fill='#000099'/>

                  <Brush />

                  {/* <Tooltip filterNull={true} allowEscapeViewBox={{x: false, y: true}} /> */}
                  {/* <Tooltip content={<this.CustomSkyposTooltip />} cursor={{strokeDasharray: '3 3'}}/> */}
                  <Legend verticalAlign="top" />
                  
                  <ReferenceLine x={this.state.sun_earth_data[0].sun_azimuth} stroke="#999900"
                                 label={<Label value={this.state.sun_direction} fill={'#999900'} /> }
                  />

                </ComposedChart>
              </ResponsiveContainer>
            }
            {/* Download Horizon as CSV Button */}
            {this.state.apiresult && 
              <CardActions className={classes.cardActions} >
                <div className={classes.buttonWrapper}>

                  <Button
                    size="small" 
                    startIcon={<CloudDownloadIcon />}
                    onClick={() => {
                      if (!this.state.processing || this.state.apiresult) {
                        downloadCSVFromJson(`moon-horizon.csv`, this.state.horizon_data);
                      }
                    }}
                    variant="contained" 
                    color="primary" 
                    disabled={this.state.processing || !Boolean(this.state.horizon_data.length>0)} 
                  >
                    Horizon as CSV
                  </Button>
                </div>
              </CardActions>
            }

            {/* Chart 2 */}
            {this.state.apiresult && 
              <ResponsiveContainer minWidth={400} minHeight={200} spacing={0}>
                <LineChart
                  data={this.state.combined_data}
                  // syncId='elevationY'
                  margin={{
                    top: 16,
                    right: 16,
                    bottom: 0,
                    left: 24,
                  }}
                >
                  <CartesianGrid />
                  {/* <XAxis dataKey={'unixtime'} type="number" name='Time' domain = {['auto', 'auto']} tickFormatter = {(unixTime) => new Date(unixTime).toISOString()} /> */}
                  
                  {/* Don't show hours if there's more than 60 items */}
                  <XAxis axisLine={false} dataKey={'time-hour'}  xAxisId={0} dy={0}   dx={-0} label={{ value: '', angle: 0, position: 'bottom' }} 
                         interval={0} 
                         tickLine={true}
                         tick={{fontSize: 14, angle: 0 } && this.state.combined_data.length<61} /> {/* Show if this.state.combined_data.length<61 */}
                  <XAxis axisLine={false} dataKey={'time-day'}   xAxisId={1} dy={-7}  dx={0}  label={{ value: '', angle: 0, position: 'bottom' }} 
                        //  interval={this.state.time_day_interval}
                         interval={Math.round(this.state.combined_data.length/50)}
                         tickLine={false} 
                         tick={{fontSize: 14, angle: 0}} />
                  <XAxis axisLine={false} dataKey={'time-month'} xAxisId={2} dy={-14} dx={0}  label={{ value: '', angle: 0, position: 'bottom' }} 
                         interval={Math.round(this.state.combined_data.length/50)*7} 
                         tickLine={false} 
                         tick={{fontSize: 14, angle: 0}} >
                    <Label value="Hour, Day, Month" offset={0} position="insideBottom" 
                           style={{ textAnchor: 'middle', fontSize: '100%', fill: '#FFFFFF' }}
                    />
                  </XAxis>
                  
                  {/* <YAxis label={{ value: 'Elevation', angle: -90, position: 'insideBottomLeft' }} /> */}
                  <YAxis>
                    <Label value="Elevation" offset={0} position="insideBottomLeft" angle="-90"
                           style={{ textAnchor: 'middle', fontSize: '100%', fill: '#FFFFFF' }}
                    />
                  </YAxis>

                  <Line dataKey={'sun_elevation'}   legendType='star'   name='Sun elevation'    unit='°' type="monotone" stroke="#999900" fill="#999900" activeDot={{ stroke: '#FFFF00', strokeWidth: 2, r: 5 }} />
                  <Line dataKey={'earth_elevation'} legendType='circle' name='Earth elevation'  unit='°' type="monotone" stroke="#000099" fill='#000099' activeDot={{ stroke: '#0000FF', strokeWidth: 2, r: 5 }} />
                  
                  {/* <Brush /> */}

                  <Tooltip cursor={{strokeDasharray: '3 3'}}  wrapperStyle={{ color: "#333333" }}/>
                  <Legend  verticalAlign="top" />

                  <ReferenceLine y={0} stroke="#999999"
                                 label={<Label value="" fill={'#999999'} /> }
                  />
                  {/* {this.state.timezone} */}
                </LineChart>

              </ResponsiveContainer>
            }
          </React.Fragment>
          
          {/* Download as CSV Button */}
          {this.state.apiresult && 
            <CardActions className={classes.cardActions} >
              <div className={classes.buttonWrapper}>

                <Button
                  size="small" 
                  startIcon={<CloudDownloadIcon />}
                  onClick={() => {
                    if (!this.state.processing || this.state.apiresult) {
                      downloadCSVFromJson(`sun-earh-positions.csv`, this.state.combined_data);
                    }
                  }}
                  variant="contained" 
                  color="primary" 
                  disabled={!this.state.combined_data || !this.state.apiresult} 
                >
                  Sun, Earth as CSV
                </Button>
              </div>
            </CardActions>
          }

          {/* CHART 3: Visible ground stations charts */}
          {this.state.apiresult && this.state.selected_gs.length > 0 &&
            <React.Fragment>
              <Box sx={{ width: '100%', minWidth: 500, marginLeft: 80, marginTop: 20, marginBottom: 20 }}>
                <Typography variant="h5" component="div" gutterTop>Visible ground stations</Typography>
              </Box>
            </React.Fragment>
          }

          {this.state.apiresult && this.state.selected_gs.map((gs,index) =>
          <React.Fragment>
            <Box sx={{ width: '100%', minWidth: 500, marginLeft: 80 }}>
              <Typography variant="h6" component="div" gutterTop>{gs}</Typography>
            </Box>
            <ResponsiveContainer minWidth={400} minHeight={100} spacing={0}>
                  <BarChart
                    data={this.getVisibleGSData(gs)}
                    margin={{
                      top: 20,
                      right: 30,
                      left: 20,
                      bottom: 5
                    }}
                    layout="horizontal"
                    barCategoryGap="0%"
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="time" type="category" />
                    <YAxis type="number">
                      {/* <Label value={gs} offset={0} position="insideBottomLeft" angle="60"
                            style={{ textAnchor: 'middle', fontSize: '100%', fill: '#FFFFFF' }}
                      /> */}
                    </YAxis>
                    <Tooltip wrapperStyle={{ color: "#333333" }}/>
                    <Legend />
                    <Bar dataKey="visible" fill="#ff00ff" />
                  </BarChart>
            </ResponsiveContainer>
          </React.Fragment>
          )}
          {this.state.apiresult && this.state.selected_gs &&
          <React.Fragment>
            <Box sx={{ width: '100%', minWidth: 500, marginLeft: 80 }}>
              <Typography variant="h6" component="div" gutterTop>Overall coverage</Typography>
            </Box>
            <ResponsiveContainer minWidth={400} minHeight={100} spacing={0}>
                  <BarChart
                    data={this.getVisibleGSData('Overall coverage')}
                    margin={{
                      top: 20,
                      right: 30,
                      left: 20,
                      bottom: 5
                    }}
                    layout="horizontal"
                    barCategoryGap="0%"
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="time" type="category" />
                    <YAxis type="number" />
                    <Tooltip wrapperStyle={{ color: "#333333" }}/>
                    <Legend />
                    <Bar dataKey="visible" fill="#ff00ff" />
                  </BarChart>
            </ResponsiveContainer>
          </React.Fragment>
          }
          
          

          {/* FORM */}
          <ValidatorForm
              className={classes.root}
              ref="form"
              // ref={r => (this.form = r)}
              onSubmit={this.handleSubmit}
              onError={error => console.log(this.state.error)}
          >
          <CardContent className={classes.cardContent}>
              <Grid container
                    justify="flex-start"
                    alignItems="center"
                    spacing={2}
                    >
                <Grid item xs={8} sm={4}>
                {/* <TextField fullWidth onChange={this.handleChange} name="latitude" id="latitude" label="Latitude" variant="outlined" /> */}
                <TextValidator
                    mr={1}
                    fullWidth
                    // error={Number.isNaN(this.state.lat)}
                    // size="small"
                    variant="outlined"
                    id="latitude" 
                    name="latitude"
                    label="Latitude"
                    value={this.state.latitude}
                    onChange={this.handleChange}
                    validators={[
                      'required',
                      'isFloat',
                      'minFloat:-90.0',
                      'maxFloat:90.0',
                    ]}
                    errorMessages={[
                      'Required field',
                      'Must be a number',
                      'Min valid number is -90.0',
                      'Max valid number is 90.0',
                    ]}
                  />
              </Grid>
              <Grid item xs={8} sm={4}>
                {/* <TextField fullWidth onChange={this.handleChange} name="longitude"  id="longitude" label="Longitude" variant="outlined" /> */}
                <TextValidator
                    fullWidth
                    // error={Number.isNaN(this.state.lon)}
                    // size="small"
                    variant="outlined"
                    id="longitude"
                    name="longitude"
                    label="Longitude"
                    value={this.state.longitude}
                    onChange={this.handleChange}
                    validators={[
                      'required',
                      'isFloat',
                      'minFloat:-180.0',
                      'maxFloat:180.0'
                    ]}
                    errorMessages={[
                      'Required field',
                      'Must be a number',
                      'Min valid number is -180.0',
                      'Max valid number is 180.0',
                    ]}
                    />
              </Grid>
              <Grid item xs={8} sm={4}>
              </Grid>
              <Grid item xs={8} sm={4} className={classes.datePicker}>
                <CDateRangePicker onChange={this.handleDateChange} selectedDate={this.state.selectedDate} />
              </Grid>
              <Grid item xs={4} sm={2}>
                <FormControl fullWidth variant="outlined" >
                  <InputLabel id="time_step">Time step</InputLabel>
                  <Select
                    labelId="time_step"
                    id="time_step"
                    name="time_step"
                    value={this.state.time_step}
                    onChange={this.handleChange}
                  >
                    <MenuItem value="1h">1 hour</MenuItem>
                    <MenuItem value="2h">2 hours</MenuItem>
                    <MenuItem value="6h">6 hour</MenuItem>
                    <MenuItem value="12h">12 hour</MenuItem>
                    <MenuItem value="24h">24 hour</MenuItem>
                  </Select>
                  <FormHelperText>Time between data points</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={4} sm={2}>
                <FormControl fullWidth variant="outlined" >
                  <TextValidator
                      fullWidth
                      // error={Number.isNaN(this.state.observer_height)}
                      // size="small"
                      variant="outlined"
                      id="observer_height"
                      name="observer_height"
                      label="Observer height"
                      value={this.state.observer_height}
                      onChange={this.handleChange}
                      validators={[
                        'required',
                        'isFloat',
                        'minFloat:0.0',
                        'maxFloat:2000.0'
                      ]}
                      errorMessages={[
                        'Required field',
                        'Must be a number',
                        'Min valid number is 0',
                        'Max valid number is 2000',
                      ]}
                      />
                    <FormHelperText>Observer height in meters</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={10} sm={5}>
                  <FormControl fullWidth variant="outlined" >
                    <InputLabel id="ground_station_provider">Ground Station provider</InputLabel>
                    <Select
                      labelId="ground_station_provider"
                      id="ground_station_provider"
                      name="ground_station_provider"
                      value={this.state.ground_station_provider}
                      onChange={this.handleChange}
                    >
                      <MenuItem value="ESTRACK">European Space Tracking network</MenuItem>
                      <MenuItem value="DSN">NASA Deep Space Network</MenuItem>
                    </Select>
                    {/* <FormHelperText>Ground station provider</FormHelperText> */}
                  </FormControl>
                </Grid>
              </Grid>

              {/* Show/Hide Ground stations list */}
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.showGroundStationsList}
                    onChange={this.handleChangeSwitch}
                    name="showGroundStationsList"
                    color="primary"
                  />
                }
                label="Show/Hide Ground stations list"
              />

              {this.state.showGroundStationsList && 
                <React.Fragment>
                  <GroundStationsList gs_list={this.state.gs_lists[this.state.ground_station_provider]} updateSelectedGS={p=>{this.updateSelectedGS(p)}} />
                </React.Fragment>
              }

                <CardActions className={classes.cardActionsRight} >
                  <div className={classes.wrapper}>
                    {/* onClick={this.handleSubmit}  */}
                    <Button type="submit" variant="contained" color="primary" disabled={this.state.processing} >
                      Submit
                    </Button>
                    {this.state.processing && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </div>
                </CardActions>
                
                <Grid item>
                  <Typography color="error">{this.state.error}</Typography>
                </Grid>
           
          </CardContent>
        </ValidatorForm>
        {/* </form> */}
      </Card>
    </Box>
    )
  }
}

const SkyposForm = withStyles(styles)(SkyposFormComponent);
// export default withStyles(styles)(SkyposForm);
export {SkyposForm};