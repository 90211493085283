import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
// import TextField from '@material-ui/core/TextField'
// import Box from '@material-ui/core/Box'
import CardActions from '@material-ui/core/CardActions';
import getApiUrl from '../../helpers/apiHelper'
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/styles';
import { green } from '@material-ui/core/colors';

const styles = theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      // width: '25ch',
    },
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonWrapper: {
    margin: theme.spacing(2,0,0,0),
    position: 'relative',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  cardActions: {
    textAlign: "center",
    // justifyContent: 'center'
    justifyContent: 'space-between',
    padding: theme.spacing(1, 2, 1)
  },
  cardActionsRight: {
    textAlign: "center",
    justifyContent: 'flex-end',
    padding: theme.spacing(1, 2, 1)
  },
  datePicker: {
    paddingTop: '10px'
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

class RadiusSearchformComponent extends Component{
  state = {
    lat: null,
    lon: null,
    radius: 1,
    processing: false,
    error: null
  }

  handleSubmit = (event) => {
    console.log('handleSubmit RadiusSearchForm')
    let isError=false;

    if (!this.state.processing) {
      // console.log('processing...')
      this.setState({processing: true});
    }

    const apiURL = getApiUrl('image_search')
    
    let {lat, lon, radius} = {...this.state}
    // let lat = this.state.lat
    // let lon = this.state.lon
    // let radius = this.state.radius

    const deg2rad = Math.PI / 180
    const rad2deg = 180 / Math.PI
    lat *= deg2rad
    lon *= deg2rad
    const R = 1737.4
    let dlat = radius / R
    let dlon_n = radius / (Math.cos(lat+dlat) * R + 1e-16)
    let dlon_s = radius / (Math.cos(lat-dlat) * R + 1e-16)
    let n = (lat + dlat) * rad2deg
    let s = (lat - dlat) * rad2deg
    let e_n = (lon + dlon_n) * rad2deg
    let e_s = (lon + dlon_s) * rad2deg
    let w_n = (lon - dlon_n) * rad2deg
    let w_s = (lon - dlon_s) * rad2deg
    let region = `POLYGON((${e_n} ${n},${e_s} ${s},${w_s} ${s}, ${w_n} ${n}, ${e_n} ${n}))`
    apiURL.searchParams.append('region', region)
    apiURL.searchParams.append('return_all', 1)
    
    fetch(apiURL.href)
      .then(response => {
        if(response.status >= 400) {
          this.setState({processing: false});
          throw new Error("Server responded with error: "+response.status+": "+response.statusText+"!");
        }
        return response.json();
      })
      .then(data => {
        this.props.onSearchComplete(data.images)
        this.setState({processing: false});
      }).catch((error) => {
          console.log(error);
          this.setState({processing: false});
          this.setState({processingSuccess: false});
          
          alert(error);
          return false;
      });
  }

  handleChange = (dir) => {
    return (event) => {
      const newState = this.state
      newState[dir] = event.target.value
      this.setState(newState)
    }
  }

  render() {
    const {classes} = this.props;
    
    return (
      <ValidatorForm
              // ref="form"
              ref={r => (this.form = r)}
              onSubmit={this.handleSubmit}
              onError={error => console.log(this.state.error)}
              // instantValidate={false}
          >
        <Grid container>
          <Grid item xs={12}>
              <Grid container justify="space-between" spacing={1}>
                <Grid item xs={4}>
                  <TextValidator
                    mr={1}
                    // error={Number.isNaN(this.state.lat)}
                    placeholder="-45.4446"
                    // clearTextOnFocus={true}
                    size="small"
                    variant="outlined"
                    label="Latitude"
                    value={this.state.lat}
                    onChange={this.handleChange('lat')}
                    validators={[
                      'required',
                      'isFloat',
                      'minFloat:-90.0',
                      'maxFloat:90.0',
                    ]}
                    errorMessages={[
                      'Required field',
                      'Must be a number',
                      'Min valid number is -90.0',
                      'Max valid number is 90.0',
                    ]}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextValidator
                    // error={Number.isNaN(this.state.lon)}
                    placeholder="177.5991"
                    size="small"
                    variant="outlined"
                    label="Longitude"
                    value={this.state.lon}
                    onChange={this.handleChange('lon')}
                    validators={[
                      'required',
                      'isFloat',
                      'minFloat:-180.0',
                      'maxFloat:180.0'
                    ]}
                    errorMessages={[
                      'Required field',
                      'Must be a number',
                      'Min valid number is -180.0',
                      'Max valid number is 180.0',
                    ]}
                    />
                </Grid>
                <Grid item xs={4}>
                  <TextValidator
                    // error={Number.isNaN(this.state.radius)}
                    size="small"
                    variant="outlined"
                    label="Radius (km)"
                    value={this.state.radius}
                    onChange={this.handleChange('radius')}
                    validators={[
                      'required',
                      'isFloat',
                      'minNumber:0',
                      'maxNumber:100',
                    ]}
                    errorMessages={[
                      'Required field',
                      'Must be a number',
                      'Min radius is 0km', 
                      'Max radius is 100km',
                    ]}
                    />
                </Grid>
              </Grid>
          </Grid>

          <Grid item xs={12}>
            <CardActions className={classes.cardActionsRight} >
                <div className={classes.wrapper}>
                  <Button type="submit" variant="contained" color="primary" disabled={this.state.processing} >
                    Search
                  </Button>
                  {this.state.processing && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
            </CardActions>
          </Grid>

          {/* <Grid container> */}
            {/* <Box pt={2}> */}
              {/* <div className={classes.wrapper}>
                <Button type="submit" variant="contained" color="primary" disabled={this.state.processing} >
                Search
                </Button>
                {this.state.processing && <CircularProgress size={24} className={classes.buttonProgress} />}
              </div> */}
            {/* </Box> */}
          {/* </Grid> */}
        </Grid>
      </ValidatorForm>
    )
  }
}

const RadiusSearchform = withStyles(styles)(RadiusSearchformComponent);
export default RadiusSearchform;