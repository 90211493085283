export default function getApiUrl(serviceName, forTesting=false) {
    var api_version_string = '/api/v0';
    let service_string = '';

    switch(serviceName) {
        case 'ephemerides':
            service_string='ephemerides';
            // return new URL(window.location.protocol+"//"+window.location.host+api_version_string+"/ephemerides");
            break;
        case 'image_search':
            service_string='image_search';
            break;
        case 'process':
            api_version_string = '';
            service_string='httpapi/process';
            break;
        case 'horizon':
            service_string='horizon';
            break;
        case 'horizon_stream':
            service_string='horizon_stream';
            break;
        case 'elevation':
            service_string='elevation';
            break;
        case 'mission_window':
            service_string='mission_window';
            break;
        default:
            return null;
    }

    if (forTesting) {
        // return new URL(window.location.protocol+"//"+window.location.host+api_version_string+"/"+service_string);
        console.log(api_version_string+"/"+service_string+"?");
        return api_version_string+"/"+service_string+"?";
    } else {
        console.log(new URL(service_string, window.location.protocol+"//"+window.location.host+api_version_string+"/"));
        return new URL(service_string, window.location.protocol+"//"+window.location.host+api_version_string+"/");
    }

}

