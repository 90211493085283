import React from 'react';
// import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
// import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
// import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Footer from '../Common/Footer'
import IspaceLogo from '../Common/ispace_header_logo.svg';

const useStyles = makeStyles((theme) => ({
  label: {
    "&$focusedLabel": {
      color: "white"
    },
    "&$erroredLabel": {
      color: "orange"
    }
  },
  focusedLabel: {},
  erroredLabel: {},

  root: {
    height: '100vh'
  },
  image: {
    // backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundImage: 'url(https://ispace-cdn.fra1.cdn.digitaloceanspaces.com/mps-login-moon-1440px.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundColor: 'black',
      // theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'black',
      // theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    color: 'white'

  },
  gridForm: {
    backgroundColor: 'black',
      // theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
  },
  avatar: {
    margin: theme.spacing(1),
    paddingLeft: 15,
    paddingRight: 15,
    backgroundColor: '#000000',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(4),
  },
  floatingLabelFocusStyle: {
    color: "#e3f2fd"
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignInSide(props) {
  const classes = useStyles();

  return (
    

      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={false} md={7} className={classes.image}>
          <div style={{padding: "3em", textAlign: "center"}}>
          <img
                src={IspaceLogo}
                style={{ height: 150, width: 300 }}
                alt="ispace logo"
              />
            <Typography component="h1" variant="h1" style={{color: '#fff', fontWeight: "400"}}>
              Mission Planning Toolkit
            </Typography>
          </div>
        </Grid>
        
        <Grid item xs={false} sm={12} md={5} component={Paper} elevation={6} className={classes.gridForm} square>
          <div className={classes.paper}>
            {/* <Typography component="h1" variant="h5">
              Sign in
            </Typography> */}
            <form className={classes.form} noValidate onSubmit={props.formSubmit}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                autoFocus
                onChange={props.fieldChange}
                value={props.username}
                error={props.error}
                InputLabelProps={{
                  classes: {
                    root: classes.label,
                    focused: classes.focusedLabel,
                    // error: classes.erroredLabel
                  },
                }}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={props.fieldChange}
                value={props.password}
                error={props.error}
                InputLabelProps={{
                  classes: {
                    root: classes.label,
                    focused: classes.focusedLabel,
                    // error: classes.erroredLabel
                  },
                }}
              />
              {/* <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              /> */}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={props.loading}
              >
                Sign In
              </Button>
              {/* <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid> */}
              <Box mt={5}>
                <Footer />
              </Box>
            </form>
          </div>
        </Grid>
      </Grid>
  );
}
