import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import { PrivateRoute } from './components/PrivateRoute';
import { Georef } from './pages/Georef/Georef';
import { LoginPage } from './pages/LoginPage';
import { LandingPage } from './pages/LandingPage';
import { SkyposPage } from './pages/Skypos/';
import { MissionWindow } from './pages/MissionWindow/';

// Material-ui strict problem: https://github.com/mui-org/material-ui/issues/13394
// import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { MuiThemeProvider, unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';

// Mock REST worker
if (process.env.NODE_ENV === 'development') {
    console.log('Starting Mock REST worker!')
    const { worker } = require('./mocks/browser')
    worker.start()
  }


const darkTheme = createMuiTheme({
  palette: {
    type: "dark",
    // primary: {
    //     main: '#bbdefb',
    //   }
    // grey: {
    //   800: "#000000", // overrides failed
    //   900: "#121212" // overrides success
    // }
  }
});

export default class App extends React.Component {
    render() {
        return (
            <MuiThemeProvider theme={darkTheme}>
            <div className="jumbotron">
                <div className="container">
                    <div className="col-sm-8 col-sm-offset-2">
                        <Router>
                            <div>
                                {/* Don't use /api - /api is already used in the ingress.yaml for grpc-gateway */}
                                <PrivateRoute exact path="/" component={LandingPage} />
                                <PrivateRoute exact path="/georef" component={Georef} />
                                <PrivateRoute exact path="/skypos" component={SkyposPage} />
                                <PrivateRoute exact path="/missionwindow" component={MissionWindow} />
                                
                                <Route path="/login" component={LoginPage} />
                            </div>
                        </Router>
                    </div>
                </div>
            </div>
            </MuiThemeProvider>
        );
    }
}

export { App }; 